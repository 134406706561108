import {  useEffect, useState } from 'react'
import { getAllPagesByBoardId, getPageById } from '../services/pages'

const useGetPages: any = (boardId: any) => {
  const [pages, setPages] = useState<any>(null)
  const [page, setPage] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageloading, setPageLoading] = useState<boolean>(false)

  const getPagesHandler = async () => {
    if (boardId) {
      try {
        setLoading(true)
        const pageRes = await getAllPagesByBoardId(boardId)
        setPages(pageRes.data.data.pages)
      } catch (error) {
        // console.error("Error fetching pages:", error)
      } finally {
        setLoading(false)
      }
    }
  }
  const getPageHandler = async (id: any) => {
    try {
      setPageLoading(true)
      const pageRes = await getPageById(id)
      setPage(pageRes.data.data.page)
      if (pageRes.data.success) {
        return pageRes.data.data.page
      }
    } catch (error) {
      return error
    } finally {
      setPageLoading(false)
    }
  }
  useEffect(() => {
    getPagesHandler()
  }, [boardId])

  return { pages, loading, getPagesHandler, setPages, getPageHandler, page, pageloading }
}

export default useGetPages
