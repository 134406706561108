import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllArchivedBoardByUserId} from '../../services/boards'

// First, create the thunk
export const fetchArchivedBoardByUserId = createAsyncThunk(
  'board/fetchArchivedBoardByUserId',
  async (data: any) => {
    try {
      const response = await getAllArchivedBoardByUserId(data.userId , data.limit , data.page)
    
      return  response.data
    } catch (error: any) {
      // console.log(error)
    }
  }
)

const initialState: any = {
  entities: {},
  loading: false,
  errors: {},
}

export const archivedBoardSlice:any = createSlice({
  name: 'fetchArchivedBoardByUserId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArchivedBoardByUserId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchArchivedBoardByUserId.fulfilled, (state, action) => {
      state.entities = {...action.payload}
      state.loading = false
    })
    builder.addCase(fetchArchivedBoardByUserId.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})

export default archivedBoardSlice.reducer
