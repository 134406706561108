import React, {useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {createColumn} from '../../services/column'
import {useAuth} from '../../modules/auth'
import clsx from 'clsx'
import * as Yup from 'yup'
import '../../styles/ColumnButton.css'
import {webSocketOperations} from '../../constants'
import {capitalizeEachWord} from '../../helper-functions/CapitalizeName'
import { useTheme } from '../../context/ThemeContext'

type Props = any

const listSchema = Yup.object().shape({
  columnName: Yup.string()
    .min(3, 'Minimum 3 Characters ')
    .max(100, 'Maximum 100 Characters')
    .trim(),
  // .max(100, 'Maximum 100 Characters').required('Column Name is Required').trim(),
})

const initialValues = {
  columnName: '',
}

const ColumnButton: React.FC<Props> = ({
  scroll,
  columnResponse,
  setColumnsResponse,
  sendJsonMessage,
}: Props) => {
  const [activeClass, setActiveClass] = useState(false)

  const [debounceBtn, setDebounceBtn] = useState(false)
  const params: any = useParams()
  const {currentUser} = useAuth()
  const {id, fullName} = currentUser?.data.user

  const divRef: any = useRef(null)
  const inputRef: any = useRef(null)

  const toggleClassHandler = () => {
    setActiveClass(!activeClass)
    formik.resetForm()
  }

  const formik = useFormik({
    initialValues,
    validationSchema: listSchema,
    validateOnBlur: false,
    onSubmit: async (values, {resetForm}) => {
      if (values.columnName === '') return
      setDebounceBtn(true)

      const data = {
        created_by: fullName,
        last_modified_by: fullName,
        color: '#000',
        position:
          columnResponse.length === 1
            ? 1
            : columnResponse.length > 1
            ? columnResponse.length + 1
            : 0,
        name: values.columnName,
        boardId: params.boardId,
        version: null,
        status: null,
      }

      try {
        const resCol = await createColumn(data, id)
        const newCol = resCol.data.data
        setColumnsResponse([...columnResponse, newCol])
        const webSocketPayload: any = {
          event: 'publish',
          channel: params.boardId,
          userId: id,
          command: 'CREATE',
          operation: webSocketOperations.columnCreate,
          payload: {
            newColumn: newCol,
            boardId: params.boardId,
            alert: `${capitalizeEachWord(fullName)} created a new column ${newCol.name}.`,
          },
        }
        sendJsonMessage(webSocketPayload)

        setTimeout(() => {
          scroll(1000)
        }, 500)
      } catch (error) {
        return error
      } finally {
        resetForm()
        inputRef.current?.focus() // Refocus input after submission
        setTimeout(() => {
          setDebounceBtn(false)
        }, 1000)
      }
    },
  })

  useEffect(() => {
    if (activeClass) {
      document.getElementsByName('columnName')[0].focus()
      inputRef.current?.focus()
    }
  }, [activeClass])

  // useEffect(() => {
  //     const handleClickOutside = (event:any) => {
  //       if (divRef.current && !divRef.current.contains(event.target)) {
  //         toggleClassHandler()
  //       }
  //     };

  //     document.addEventListener('click', handleClickOutside);

  //     return () => {
  //       document.removeEventListener('click', handleClickOutside);
  //     };
  //   }, []);

  const {theme} =useTheme()

  return (
    <div className='col-btn-container my-3 ms-2'>
      <div
        ref={divRef}
        onClick={toggleClassHandler}
        className={
          activeClass
            ? 'active d-none'
            : 'col-btn-collapsible btn text-start btn-primary fs-5 p-3 btn text-start rounded-0'
        }
        id='col-btn-collapsible'
      >
        + Add Column
      </div>
      <div className={activeClass ? 'col-btn-content' : 'd-none'}>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
          <input
            maxLength={100}
            ref={inputRef}
            id='usr'
            placeholder='Add Column'
            {...formik.getFieldProps('columnName')}
            type='text'
            name='columnName'
            className={clsx(
              `form-control form-control-lg form-control-solid  ${
                theme === 'dark' && 'dark__theme__input'
              }`,
              {'is-invalid': formik.touched.columnName && formik.errors.columnName}
              // {
              //     'is-valid': formik.touched.columnName && !formik.errors.columnName,
              // }
            )}
          />
          {formik.touched.columnName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.columnName}
                </span>
              </div>
            </div>
          )}

          <div className={`buttons d-flex justify-content-between align-items-center mt-2`}>
            <button type='submit' className='btn btn-primary px-3 py-2' disabled={debounceBtn}>
              Add
            </button>

            <button
              type='button'
              className='btn-close close'
              id='close'
              aria-label='Close'
              onClick={toggleClassHandler}
            ></button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ColumnButton
