/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const createCard = async (data: any, userId: string) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card?userId=${userId}`,
    data
  )
}
export const getAllCard = async () => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/card/`)
}

export const getAllCardByBoardId = async (boardId: string, userId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/board/${boardId}?userId=${userId}`
  )
}

export const getAllCardByBoardIdForExcelExport = async (boardId: string, userId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/board/${boardId}/excel?userId=${userId}`
  )
}
export const getLastUpdatedCard = async () => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/card/last/updated`)
}

export const updateBoardNameInAllCards = async (data: any) => {
  return await apiService.patch(`${process.env.REACT_APP_BACKEND_URL}/card/boardName`, data)
}
export const updateCardColumnAndPosition = async (data: any, userId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V2}/card/update/column?userId=${userId}`,
    data
  )
}
export const updateCardById = async (data: any, cardId: any, userId: string, boardId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/${cardId}?userId=${userId}&boardId=${boardId}`,
    data
  )
}
export const deleteFileAttachment = async (
  cardId: string,
  key: string,
  userId: string,
  boardId: string
) => {
  return await apiService.delete(
    `${process.env.REACT_APP_BACKEND_URL}/card/file-attachments/${cardId}?fileId=${key}&userId=${userId}&boardId=${boardId}`
  )
}

export const getAllCardByUserId = async (
  userId: string,
  boardStatus = 'active',
  filterOptions: any
) => {
  const {days, singleDate, dateRange} = filterOptions
  return await apiService.get(
    `${
      process.env.REACT_APP_BACKEND_URL_V3
    }/card/getby/user/${userId}?boardStatus=${boardStatus}&filterDate=${days}&from=${
      singleDate.from || dateRange.from
    }&to=${singleDate.to || dateRange.to}`
  )
}

export const changeCardStatus = async (cardId: string, status: string, userId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/change/status/${cardId}?userId=${userId}`,
    {
      status,
    }
  )
}
export const getSortedCardByBoardId = async (boardId: any) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/board/sort/${boardId}`)
}
export const sortCardByBoardId = async (data: any, boardId: string, userId: string) => {
  return await apiService.patch(
    `${process.env.REACT_APP_BACKEND_URL}/board/${boardId}?userId=${userId}`,
    data
  )
}

export const cloneCard = async (data: any, userId: string) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL_V3}/card/clone?userId=${userId}`,
    data
  )
}
export const getCardDescriptionById = async (cardId: any) => {
  return await apiService.get(`${process.env.REACT_APP_BACKEND_URL_V3}/card/${cardId}`)
}