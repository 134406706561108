import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable';
import { useTheme } from '../../context/ThemeContext'
import { createNewTag } from '../../services/tags';

const SelectTags: React.FC<any> = ({ tags, setSelectedTags, selectedTags, transformedTags, boardPerson }) => {
    const { theme: themeDark } = useTheme()
    const [rightsMsg, setRightsMsg] = useState('')

    const colourStyles: any = {
        control: (styles: any) => ({ ...styles, backgroundColor: themeDark === 'dark' ? '' : 'white' }),
        multiValue: (styles: any) => {
            return {
                ...styles,
                backgroundColor: themeDark === 'dark' ? '#141520' : '#E6E6E6',
            }
        },
        multiValueLabel: (styles: any, { data }: any) => ({
            ...styles,
            backgroundColor: data.isDisabled ? '#FF677E' : '',
            color: data.isDisabled ? 'white' : '',
        }),
        multiValueRemove: (styles: any, { data }: any) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: '#FF677E',
                color: 'white',
            },
        }),
        valueContainer: (styles: any) => ({
            ...styles,
            overflow: 'auto',
            alignItems: 'flex-start',
            maxHeight: '150px',
            minHeight: '100px',
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            color: themeDark === 'dark' ? '#FF7C00' : 'hsl(0, 0%, 80%)',
        }),
        placeholder: (styles: any) => ({
            ...styles,
            color: themeDark === 'dark' ? '#98C6F0' : 'hsl(0, 0%, 80%)',
        }),
    }
    const handleCreate = async (inputValue: string) => {
        if (boardPerson.role !== 'Admin') {
            setRightsMsg('Do not have rights to create tags')
            setTimeout(() => {
                setRightsMsg('')
            }, 5000);
            return;
        }
        try {
            const response = await createNewTag(inputValue)
            const newTag = response.data.data.tag;
            setSelectedTags((prevTags: any) => [...prevTags, { label: newTag.name, value: newTag.id }]);
            if (transformedTags) transformedTags = [...transformedTags, { label: newTag.name, value: newTag.id }];
        } catch (error) {
            // console.error('Error creating tag:', error);
        }
    };
    return (
        <>
            <CreatableSelect
                isMulti
                isClearable
                isDisabled={boardPerson?.role === 'Guest'}
                placeholder="Select / Create tags..."
                options={tags}
                value={selectedTags}
                onChange={(e: any) => setSelectedTags(e)}
                onCreateOption={handleCreate}
                styles={colourStyles}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral0: themeDark === 'dark' ? "rgb(57,73,89)" : theme.colors.neutral0,
                        neutral80: themeDark === 'dark' ? "#F4FFFD" : theme.colors.neutral80,
                        primary25: themeDark === 'dark' ? "#141520" : theme.colors.primary25
                    },
                })}
            />
            {rightsMsg.length > 0 && (<div className='text-danger'>{rightsMsg}</div>)}
        </>
    )
}

export default SelectTags
