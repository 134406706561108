import React, {useEffect, useState} from 'react'
import {getCardDescriptionById} from '../services/cards'

const useGetDescription = (
  id: string
): {
  fetchDescription: () => void
  cardDescription: null | string
  setCardDescription: React.Dispatch<React.SetStateAction<null | string>>
  isCardDescriptionLoading: boolean
} => {
  const [cardDescription, setCardDescription] = useState<null | string>(null)
  const [isCardDescriptionLoading, setCardDescriptionLoading] = useState<boolean>(false)

  const fetchDescription = async () => {
    try {
      setCardDescriptionLoading(true)
      const res = await getCardDescriptionById(id)
      const descData = res.data.data.card.description
      if (descData) {
        setCardDescription(descData)
      }
    } catch (error: any) {
      return error
    } finally {
      setCardDescriptionLoading(false)
    }
  }

  useEffect(() => {
    fetchDescription()
    //eslint-disable-next-line
  }, [])

  return {cardDescription, isCardDescriptionLoading, setCardDescription,fetchDescription}
}

export default useGetDescription
