import React from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useAuth } from '../auth'
import { fetchBoardByUserId } from '../../store/slice/board-slice'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { UsersListWrapper } from './users-list/UsersList'
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/user-management/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const UsersPage :React.FC = () => {

  const { currentUser } = useAuth()
  const { id } = currentUser?.data.user
  const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
  dispatch(fetchBoardByUserId({ userId: id,isAdmin: true }))
    //eslint-disable-next-line
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='users/:boardId'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Users list</PageTitle>
              <UsersListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/user-management/users' />} />
    </Routes>
  )
}

export default UsersPage
