import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'
import useVersioning from '../../hooks/useVersioning'

const VersionUpdateModal:React.FC = () => {
    const {theme} =useTheme()
    const {reload,setReload}=useVersioning()

  return (
    <Modal
        contentClassName={theme === 'dark' ? 'layout__theme__div' : ''}
        show={reload}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className={theme === 'dark' ? 'white__text' : ''}>New Update!</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-start align-items-center flex-column'>
            <h2 style={{color: 'gray'}}> New updates of the app is available. Please refresh the page.</h2>
            <div className='align-self-end' style={{marginTop: '40px'}}>
              <Button variant='secondary' className='btn-sm' onClick={()=>{
                setReload(false)
                window.location.reload()}}>
                Reload
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  )
}

export default VersionUpdateModal
