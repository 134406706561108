import {useState} from 'react'
import {getAllCardByUserId} from '../services/cards'
import {useAuth} from '../modules/auth'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import {addHomeCardData} from "../store/slice/card-slice"

const useGetHomeCards = ():any => {
  const dispatch: Dispatch<any> = useDispatch()
  const [allCards, setAllCards] = useState<any>([])
  const [homeFetchCardLoader, setHomeFetchCardLoader] = useState<any>(false)
  const {currentUser} = useAuth()
  const {id} = currentUser?.data.user
  const cardsHandler = async (cardsStatus: string,dateFilters:any) => {
    try {
      setHomeFetchCardLoader(true)
      const response:any = await getAllCardByUserId(id, cardsStatus,dateFilters)
      if (response && response.data) {
        setAllCards(response.data.data);
        dispatch(addHomeCardData(response.data.data));
        setHomeFetchCardLoader(false)
      }
    } catch (error) {
      return error
    }
  }

  return {allCards, setAllCards, cardsHandler , homeFetchCardLoader}
}

export default useGetHomeCards
