import React, {useEffect, useState} from 'react'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../auth'
import {useManageContacts} from '../../../../hooks'
import {getAllRoles} from '../../../../services/user-lookup'
import {sendInvitation} from '../../../../services/invitation'
import {Tab, Tabs} from 'react-bootstrap'
import {webSocketOperations} from '../../../../constants'
import AccountContacts from './AccountContacts'
import InviteContacts from './InviteContacts'
import {getOrganizationMemberById} from '../../../../services/company'
import SearchBar from '../../../../components/Search/SearchBar'
// import useCompany from '../../../../hooks/useCompany'
import {useSelector} from 'react-redux'
import CustomAlert from '../../../../components/CustomAlert'
import {InviteContactsProps} from '../../../../models/Contacts'
import {useTheme} from '../../../../context/ThemeContext'

type Props = {
  isUserLoading: boolean
  user: any
  getMembersHandler: () => void
  membersData: any
  sendWebSocket: any
  handleClose: () => void
  setErrorMessage: any
  errorMessage: any
  isPage:boolean
}

export type CombinedProps = Props & Omit<InviteContactsProps, keyof Props>

const UserEditModalForm: React.FC<CombinedProps> = ({
  getMembersHandler,
  membersData,
  sendWebSocket,
  handleClose,
  setErrorMessage,
  errorMessage,
  isPage
}: Props) => {
  const [roleList, setRoleList] = useState<any>([])
  const [memberLoading, setMemberLoading] = useState<any>(false)
  //eslint-disable-next-line
  const [inviteMessage, setInviteMessage] = useState<any>('')
  //eslint-disable-next-line
  const [invitationErrorMsg, setInvitationErrorMsg] = useState('')
  const [formValues, setFormValues] = useState({})
  // const {companyDetails} = useCompany()
  const {companyDetail: companyDetails} = useSelector((state: any) => state.companyDetailReducer)
  const {setItemIdForUpdate} = useListView()
  const {refetch}: any = useQueryResponse()
  const {
    otherContactList,
    getOtherContactHandler,
    loading,
    filterOtherContactHandler,
    members,
    setMember,
    filterTeam,
    filterOther,
    setFilterOther,
    setFilterTeam,
    filterFlag,
    setFilterFlag,
  } = useManageContacts()

  const params = useParams()
  const {theme} = useTheme()
  // const {allBoards} = useGetAllBoards()
  const {allBoards} = useSelector((state: any) => state.allBoardsReducer)

  const {currentUser} = useAuth()
  const {organizationId} = currentUser

  const {id, fullName, email: userEmail} = currentUser?.data.user

  const board = allBoards?.find((item: any) => item.board.id === params.boardId)

  const [selectedRows, setSelectedRows] = useState<any>([])

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const getRolesHandler = async () => {
    try {
      const roleRes = await getAllRoles()
      setRoleList(roleRes.data.data)
    } catch (error) {
      return error
    }
  }

  const handleSelectedRowsChange = (rowId: any, email: any, role: any) => {
    // Check if the row is already in the selectedRows array
    const existingRow = selectedRows.find((row: any) => row.id === rowId)
    if (existingRow) {
      // If the row is already selected, update its role
      existingRow.role = role
    } else {
      // If the row is not selected, add it to the selectedRows array
      setSelectedRows([...selectedRows, {id: rowId, email, role}])
    }
  }
  const sendInvitations = async () => {
    // Iterate over selectedRows and send invitations to the selected rows
    const inviteeArray = selectedRows.map((selectedRow: any) => {
      const {email, role: selectedRole} = selectedRow
      const roleId = roleList.find((roles: any) => roles.name === selectedRole)?.id
      const invitation = {
        email: email,
        boardId: board?.board.id,
        roleId: roleId,
        inviteBy: fullName,
        inviteByUserId: id,
        boardName: board?.board.name.trim(),
        inviterEmail: userEmail,
      }

      return invitation
    })
    try {
      const payload: any = {inviteeArray}
      const res: any = await sendInvitation(payload)
      const msg = res.data.data[inviteeArray[0].email].message
      const isError = res.data.data[inviteeArray[0].email].error
      setInviteMessage(msg)
      if (!isError) {
        getMembersHandler()
        !isPage && sendWebSocket(webSocketOperations.boardDesignInviteeUpdate)
        setTimeout(() => {
          cancel(true)
        }, 4000)
      }

      // getMembersHandler()
      // sendWebSocket(webSocketOperations.boardDesignInviteeUpdate)
      // cancel(true)
    } catch (error: any) {
      const errorMsg = error.response.data.message
      if (errorMsg === 'Already Accepted') {
        setInvitationErrorMsg('Invitation Already Accepted!')
      }
      setInvitationErrorMsg(errorMsg)
    }finally{
      setTimeout(() => {
      handleClose()
      }, 3000);
    }
  }

  const getCompanyMembers = async () => {
    setMemberLoading(true)
    try {
      const memberRes = await getOrganizationMemberById(organizationId)

      setMember(memberRes.data.data)
    } catch (error) {
      return error
    } finally {
      setMemberLoading(false)
    }
  }

  useEffect(() => {
    if (organizationId) {
      getCompanyMembers()
    }
    getOtherContactHandler()
    getRolesHandler()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      {/* {currentAccount.isCompany && ( */}
      <>
        <CustomAlert
          variant={'info'}
          className='my-3'
          alertMessage={invitationErrorMsg}
          setAlertMessage={setInvitationErrorMsg}
        />

        <Tabs
          defaultActiveKey='team'
          id='uncontrolled-tab-example'
          className='mb-3 text-center fs-6  w-100'
          //bg-white
        >
          <Tab
            eventKey='team'
            title={`${organizationId ? 'My Team &' : ''} Other Contacts`}
            className={` ${theme === 'dark' ? 'theme__div' : 'bg-white'}`}
          >
            <>
              <div className='w-100'>
                <div className='d-flex justify-content-between flex-wrap'>
                  {organizationId && (
                    <div className={`fs-2 fw-semibold mt-5 mb-2 d-flex align-items-end ${theme === 'dark' ?'text-white' : 'text-dark'}`}>
                      {companyDetails?.data?.organizationName} contacts
                    </div>
                  )}
                  <div className='card-body py-3'>
                    <div className='mb-2 d-flex justify-content-end'>
                      <SearchBar
                        filterFunction={filterOtherContactHandler}
                        setFilterTeam={setFilterTeam}
                        setFilterOther={setFilterOther}
                        setFilterFlag={setFilterFlag}
                        filterTeam={filterTeam}
                      />
                    </div>
                  </div>
                </div>
                {organizationId && (
                  <>
                    {!filterFlag && (
                      <AccountContacts
                        membersData={membersData}
                        setSelectedRows={setSelectedRows}
                        selectedRows={selectedRows}
                        flag={true}
                        otherContactList={members}
                        loading={memberLoading}
                        onSelectedRowsChange={handleSelectedRowsChange}
                      />
                    )}

                    {filterTeam?.length > 0 && filterFlag && (
                      <AccountContacts
                        membersData={membersData}
                        setSelectedRows={setSelectedRows}
                        selectedRows={selectedRows}
                        flag={true}
                        otherContactList={filterTeam}
                        loading={memberLoading}
                        onSelectedRowsChange={handleSelectedRowsChange}
                      />
                    )}

                    {!loading && filterTeam?.length < 1 && filterFlag && (
                      <h5 className={`text-center mt-10 ${theme === 'dark' ? 'white__text' : ''}`}>No Contacts Available</h5>
                    )}

                    <div className='fs-2 fw-semibold mt-5 mb-0'>Other contacts</div>
                  </>
                )}

                {!filterFlag && (
                  <AccountContacts
                    membersData={membersData}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    flag={!organizationId ? true : false}
                    otherContactList={otherContactList}
                    loading={loading}
                    onSelectedRowsChange={handleSelectedRowsChange}
                  />
                )}

                {filterOther?.length > 0 && filterFlag && (
                  <AccountContacts
                    membersData={membersData}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    flag={!organizationId ? true : false}
                    otherContactList={filterOther}
                    loading={loading}
                    onSelectedRowsChange={handleSelectedRowsChange}
                  />
                )}

                {!loading && filterOther?.length < 1 && filterFlag && (
                  <h5 className={`text-center mt-10 ${theme === 'dark' ? 'white__text' : ''}`}>No Contacts Available</h5>
                )}
              </div>
              <CustomAlert
                variant={'danger'}
                alertMessage={errorMessage}
                setAlertMessage={setErrorMessage}
              />
              <CustomAlert
                variant={'success'}
                alertMessage={inviteMessage}
                setAlertMessage={setInviteMessage}
              />
              <div className='w-100 text-end pt-5'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={sendInvitations}
                  disabled={selectedRows.length < 1 ? true : false}
                >
                  Send Invitation
                </button>
              </div>
            </>
          </Tab>
          <Tab
            eventKey='new'
            title='Create & Invite Contacts'
            className={`${theme === 'dark' ? 'theme__div' : 'bg-white'}`}
          >
            <div className=' w-100 '>
              {organizationId && (
                <div className={`fs-2 fw-semibold my-5 ${theme === 'light' ? 'dark__text' : ''}`}>Create and Invite New Contacts</div>
              )}
              <InviteContacts
                getMembersHandler={getMembersHandler}
                buttonTitle={'Invite to Board & Add to Contacts'}
                setFormValues={setFormValues}
                formValues={formValues}
                handleClose={handleClose}
                inviteFlag={true}
              />
            </div>
          </Tab>
        </Tabs>
      </>
      {/* )} */}
    </>
  )
}

export {UserEditModalForm}
