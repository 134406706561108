import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useTheme} from '../../context/ThemeContext'

type Props = {
  showDeleteModal: boolean
  handleCloseDeleteModal: () => void
  title: string
  deleteModalMessage: string
  onDeleteHandler: () => void
}

const DeleteColumnModal: React.FC<Props> = ({
  deleteModalMessage,
  showDeleteModal,
  handleCloseDeleteModal,
  title,
  onDeleteHandler,
}: Props) => {
  const {theme} = useTheme()
  return (
    <Modal
      contentClassName={`${theme === 'dark' ? 'theme__div' : ''} ${deleteModalMessage && 'red_border'}`}
      show={showDeleteModal}
      onHide={handleCloseDeleteModal}
    >
      <Modal.Header>
        <Modal.Title
          className={`text-truncate ${theme === 'dark' ? 'theme__div' : ''}`}
        >{`Delete "${title?.trim()}"`}</Modal.Title>
        <i className={`bi bi-x-lg modal-icon-custom ${theme === 'dark' && 'close'}`} onClick={handleCloseDeleteModal}></i>

      </Modal.Header>
      <Modal.Body style={{wordBreak: 'break-all'}}>
        {deleteModalMessage ? (
          <span >{deleteModalMessage}</span>
        ) : (
          <span className={theme === 'dark' ? 'white__text' : ''}>
            Are you sure you want to delete this Column?
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCloseDeleteModal}>
          No
        </Button>
        <Button variant='primary' disabled={!!deleteModalMessage} onClick={onDeleteHandler}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteColumnModal
