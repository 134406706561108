import React, {useState, useEffect} from 'react'
import {login, register} from '../core/_requests'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAuth} from '../core/Auth'
import 'react-phone-input-2/lib/style.css'

import {useFormik} from 'formik'
import {isValidPhoneNumber} from 'libphonenumber-js'
import {useTogglePassword} from '../../../hooks'
import PhoneInput from 'react-phone-input-2'

import {useDispatch, useSelector} from 'react-redux'
import {setSession} from '../../../store/slice/auth-slice'
import CustomAlert from '../../../components/CustomAlert'
import {acceptInvitation} from '../../../services/invitation'
import {useTheme} from '../../../context/ThemeContext'

//eslint-disable-next-line
const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@~\$%\^\*])(?=.{12,})/
// const nameReg = /^[a-zA-Z ._-]*$/
const registrationIndividualSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, 'Maximum 40 characters are allowed.')
    .required('Name is required')
    .trim(),
  // .matches(nameReg, 'Name should be in Upper or Lower case letters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .trim()
    .matches(
      passwordReg,
      'Please use 12 or more characters with a mix of letters, numbers and any of the following special characters: !,@,~,$,%,^ or *'
    ),
})

export function GuestRegistration(): React.JSX.Element {
  const dispatch = useDispatch()
  const {theme} = useTheme()
  const [loading, setLoading] = useState(false)
  const location: any = useLocation()
  const [popUpMsg, setPopUpMsg] = useState('')
  const [phoneState, setPhoneState] = useState('')
  const [errorMsgRegistration, setErrorMsgRegistration] = useState('')
  const {passwordType, togglePassword} = useTogglePassword()
  const {saveAuth, setCurrentUser} = useAuth()
  const {redirectUrl} = useSelector((state: any) => state.RedirectionReducer)
  const decodedSignupData = location?.state?.decodedSignupData
  const initialValues = {
    name: '',
    email: decodedSignupData?.email,
    password: '',
    image: null,
    boardName: null,
    pageTitle: null,
    roleId: decodedSignupData.roleId,
  }

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationIndividualSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      let validNumber = true
      if (phoneState.length > 0) {
        validNumber = isValidPhoneNumber('+' + phoneState)
      }
      if (validNumber) {
        const isInvitedByOrganization = false
        try {
          const {data: auth} = await register(
            values.boardName,
            values.pageTitle,
            values.email,
            values.name,
            values.password,
            phoneState.length > 0 ? phoneState : null,
            values.image,
            values.roleId,
            isInvitedByOrganization,
            'FreeTrial'
          )
          setPopUpMsg('Registration Successful!')

          setTimeout(() => {
            loginHandler(decodedSignupData.email, values.password)
          }, 5000)
          const acceptPayload = {
            email: decodedSignupData.email,
            boardId: decodedSignupData.boardId,
            roleId: decodedSignupData.roleId,
            userId: auth.user.id,
            isAdmin: false,
            inviteByUserId: decodedSignupData.inviteByUserId,
          }
          await acceptInvitation(acceptPayload)
          setLoading(false)
        } catch (error: any) {
          const msg = error.response.data.message
          setErrorMsgRegistration(msg)
        } finally {
          setSubmitting(false)
          setLoading(false)
          formik.resetForm()
        }
      } else {
        setPopUpMsg('Please Enter Valid Phone Number!')
      }
      setSubmitting(false)
      setLoading(false)
    },
  })

  useEffect(() => {
    const auth: any = localStorage.getItem('auth')
    const authData = JSON.parse(auth)

    if (authData) {
      localStorage.clear()
      window.location.reload()
    }
  }, [])

  const loginHandler = async (email: string, password: string) => {
    try {
      const {data: auth}: any = await login(email, password)
      setLoading(false)
      navigate(redirectUrl)
      saveAuth(auth)
      setCurrentUser(auth)
      dispatch(setSession(false))
    } catch (error: any) {
      return error
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className='mb-2 text-center'>
        <h1 className={` ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>
          Sign Up to <span className='fw-semibold'>B4i</span>
          <span className='text-primary fw-semibold'>GO</span>
        </h1>
      </div>

      <form
        className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${theme === 'dark' ? 'theme__div' : ''}`}
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className={`row fv-row mt-5   ${theme === 'dark' ? 'theme__div' : ''}`}>
          <div className='col-xl-12 my-3'>
            <label className={`form-label fw-bold ${theme === 'dark' ? 'white__text' : 'text-dark'}  fs-6`}>
              Please enter your name
            </label>
            <input
              placeholder='Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('name')}
              className={clsx(
                `form-control form-control-lg form-control-solid  ${
                  theme === 'dark' ? 'dark__theme__input' : ''
                }  italics-placeholder`,
                {
                  'is-invalid': formik.touched.name && formik.errors.name,
                },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='text-danger'>
                    {formik.errors.name}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xl-12 my-3'>
            <label 
            className={`form-label fw-bold ${theme === 'dark' ? 'white__text' : 'text-dark'} mb-0 fs-6`}
            >Your email address</label>
            <input
              disabled
              placeholder='Email Address'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                `form-control form-control-lg form-control-solid  ${
                  theme === 'dark' ? 'dark__theme__input' : ''
                }  italics-placeholder`,
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='text-danger'>
                    {formik.errors.email as string}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className='col-xl-12 my-3' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label  className={`form-label fw-bold ${theme === 'dark' ? 'white__text' : 'text-dark'} mb-0 fs-6`}>Phone (optional)</label>
              <div className='position-relative mb-3'>
                <PhoneInput
                  placeholder='+44 7700 900077'
                  country='44'
                  value={phoneState}
                  onChange={(phone) => setPhoneState(phone)}
                  inputClass={`w-100 ${theme === 'dark' ? 'white__text' : ''}`}
                          dropdownClass={theme === 'dark' ? 'theme__div' : ''}
                          containerClass={theme === 'dark' ? 'react-tel-input-dark' : 'react-tel-input-light'}
                />
              </div>
            </div>
          </div>

          <div className='col-xl-12 my-3'>
            <label  className={`form-label fw-bold ${theme === 'dark' ? 'white__text' : 'text-dark'} mb-0 fs-6`}>Create a password</label>
            <div className='position-relative mb-3'>
              <div className='input-group'>
                <input
                  type={passwordType}
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    
                    `form-control form-control-lg form-control-solid  ${
                      theme === 'dark' ? 'dark__theme__input' : ''
                    }  italics-placeholder`,
                    {
                      'is-valid':
                        phoneState.length > 0 && formik.touched.password && !formik.errors.password,
                    }
                  )}
                />

                <div className='input-group-btn'>
                  <button
                  
                    className={`${theme === 'dark' ? 'theme__div' : ''} btn border-end border-top border-bottom`}

                    style={{borderRadius: '0px 10px 10px 0px'}}
                    type='button'
                    onClick={togglePassword}
                  >
                     {passwordType === 'password' ? (
                            <i
                              className={`  bi bi-eye-slash  ${
                                theme === 'dark' ? 'white__text' : ''
                              }`}
                            ></i>
                          ) : (
                            <i className={`bi bi-eye ${theme === 'dark' ? 'white__text' : ''}`}></i>
                          )}
                  </button>
                </div>
              </div>

              {
                // phoneState.length > 0 &&
                formik.touched.password && formik.errors.password ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.password}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='' style={{color: 'gray'}}>
                        Please use 12 or more characters with a mix of letters, numbers and any of
                        the following special characters: !,@,~,$,%,^ or *
                      </span>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        <div className='my-3'>
          <CustomAlert variant={'info'} alertMessage={popUpMsg} setAlertMessage={setPopUpMsg} />
        </div>

        <div className='my-3'>
          <CustomAlert
            variant={'danger'}
            alertMessage={errorMsgRegistration}
            setAlertMessage={setErrorMsgRegistration}
            time={5000}
          />
        </div>

        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Sign Up</span>}
            {loading && <span className='indicator-label'>Loading...</span>}
          </button>
          <Link to='/auth'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-secondary w-100 mb-5'
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </>
  )
}
