/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {FileUploadPage} from '../FileUploader/FileUpload'
import 'react-datepicker/dist/react-datepicker.css'
import SendAlertTo from '../Select/SendAlert'
import Comments from '../Comments/Comments'
import CustomDatePicker from '../CustomDatePicker'
import AssignTo from '../Select/AssignTo'
import SelectBoard from '../Select/SelectBoard'
import SelectColumn from '../Select/SelectColumn'
import clsx from 'clsx'
import {TextEditor} from '../TextEditor'
import CustomAlert from '../CustomAlert'
import Skeleton from 'react-loading-skeleton'
import React, {useRef, useEffect, useState} from 'react'
// import { useAuth } from '../../modules/auth'
import {useGetMember} from '../../hooks'
import Remainder from '../Remainder'
import '../../styles/updateanaddform.css'
import {removeDuplication} from '../../helper-functions/CapitalizeName'
import {useTheme} from '../../context/ThemeContext'
import { defaultWhiteColor, priorityColors } from '../../utils/priorities'

const UpdateCardForm = ({
  formik,
  setCardLength,
  boardId,
  allBoardsVar,
  setBoardId,
  cardDetails,
  columns,
  columnId,
  setColumnId,
  isHomeSearching,
  deleteFileHandler,
  selectedFile,
  changeHandler,
  fileErrorMsg,
  setFileErrorMsg,
  sendAlertMsg,
  setSendAlertMsg,
  setSendAlertTo,
  sendAlertTo,
  childCommentRef,
  optionsForSendAlert,
  setSendAlertToComment,
  sendAlertToComment,
  sendAlertToBoardPerson,
  boardPersonsForSendAlert,
  setEditorComment,
  editorComment,
  sendAlertMsgComment,
  // setCommentId,
  setStartDate,
  startDate,
  options,
  setSelected,
  selected,
  // disableBtn,
  editor,
  setEditor,
  checkIsDescriptionExist,
  reminders,
  setReminders,
  setDeletedReminderIds,
  isBoardLoading,
  isColumnLoading,
  isUserAssignLoading,
  isCardDescriptionLoading
}: any) => {
  const getOptionColor = (value: string, theme:string) => {
    if(theme === 'dark'){
      return defaultWhiteColor
    }else{
      if(value){
        return priorityColors[value]
      }
    }
  };
  //   const { currentUser } = useAuth()
  //   const { role : checkRole } = currentUser?.data.user
  const {theme} = useTheme()
  const {boardPerson} = useGetMember(boardId)
  const reminderMainRef: any = useRef<any>(null)
  const [reminderMainWidth, setReminderMainWidth] = useState(0)

  useEffect(() => {
    const updateWidth = () => {
      if (reminderMainRef.current) {
        setReminderMainWidth(reminderMainRef.current.offsetWidth)
      }
    }

    updateWidth()

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  return (
    <form
      className='form row'
      onSubmit={(e) => {
        e.preventDefault()
        formik.handleSubmit()
      }}
      noValidate
    >
      <div className='col-md-12 col-lg-8 col-sm-12'>
        <div className='row mb-5'>
          <div className='col-md-12 col-lg-12 col-sm-12'>
            <label
              className={`form-label fs-6 fw-bolder ${
                theme === 'dark' ? 'white__text' : 'text-dark'
              }`}
            >
              Title
            </label>
            <input
              disabled={boardPerson?.role === 'Guest'}
              // placeholder='Email'
              {...formik.getFieldProps('cardName')}
              className={clsx(
                `form-control form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`,
                {'is-invalid': formik.touched.cardName && formik.errors.cardName},
                {
                  'is-valid': formik.touched.cardName && !formik.errors.cardName,
                }
              )}
              type='name'
              name='cardName'
              autoComplete='off'
              maxLength={300}
              placeholder='Enter Card Name'
            />
            {formik.touched.cardName && formik.errors.cardName && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger'>
                  {formik.errors.cardName}
                </span>
              </div>
            )}
          </div>
        </div>

        <h6 className={`my-3 ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>Description</h6>
       {!isCardDescriptionLoading  ? (
        <>
        {checkIsDescriptionExist  ? (
            <>
              {editor && editor.length > 0 && (
                <div style={{position: 'relative'}}>
                  <TextEditor
                    placeholder={'Write something'}
                    handleFormSubmission={formik.handleSubmit}
                    setEditor={setEditor}
                    editor={editor}
                    role={boardPerson?.role}
                    forTitle='description'
                  />
                </div>
              )}
            </>
          ) : (
            <div style={{position: 'relative'}}>
              <TextEditor
                role={boardPerson?.role}
                placeholder={'Write something'}
                handleFormSubmission={formik.handleSubmit}
                setEditor={setEditor}
                editor={editor}
                forTitle='description'
              />
            </div>
          )}
        </>
        ): 
        <React.Fragment>
          <Skeleton count={1} height={50} />
          <Skeleton count={1} height={250} />
        </React.Fragment>
      }
        
        <h6 className={`my-3 ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>Send Alert To:</h6>
        <div className='row my-2 d-flex'>
          <div className='col-md-9'>
          {isUserAssignLoading  ? (
            <Skeleton count={1} height={40} />
          ):
           ( <SendAlertTo
              role={boardPerson?.role}
              options={optionsForSendAlert}
              setSelected={setSendAlertTo}
              selected={sendAlertTo}
            />)}
          </div>
          <div className='col-md-3 col-sm-12 alert-btn'>
            <button
              disabled={boardPerson?.role === 'Guest'}
              className='btn btn-info px-2 pt-3 pb-2 '
              type='button'
              onClick={() =>
                sendAlertToBoardPerson(undefined, sendAlertTo, 'card-description', null)
              }
            >
              Send Alert
            </button>
          </div>
          <div className='col-md-9'>
            <CustomAlert
              variant={'info'}
              className={'mt-2'}
              alertMessage={sendAlertMsg}
              setAlertMessage={setSendAlertMsg}
            />
          </div>
        </div>

        <FileUploadPage
          deleteFileHandler={deleteFileHandler}
          selectedFile={selectedFile}
          changeHandler={changeHandler}
        />
        <CustomAlert
          variant={'warning'}
          className={'mt-2 w-75'}
          alertMessage={fileErrorMsg}
          setAlertMessage={setFileErrorMsg}
          time={5000}
        />
      </div>
      <div className='col-md-12 col-lg-4 col-sm-12 border px-2 py-2'>
        <h4 className={`text-center ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>Details</h4>

        <div className='row mb-5'>
          <div className='col-12 my-3'>
            <h6 className={theme === 'dark' ? 'white__text' : 'text-dark'}>Assigned to:</h6>
            {isUserAssignLoading ? (
            <Skeleton count={1} height={40} />
          ) :  (
            <AssignTo
              page={false}
              options={options}
              role={boardPerson?.role}
              setSelected={setSelected}
              selected={removeDuplication(selected)}
            />)}
          </div>
          <div className='col-12 my-2'>
            <label
              className={`${
                theme === 'dark' ? 'white__text' : 'text-dark'
              } form-label fs-6 fw-bolder `}
            >
              Board
            </label>
            {isBoardLoading ? (
            <Skeleton count={1} height={40} />
          ) :  (
          <SelectBoard
              role={boardPerson?.role}
              setCardLength={setCardLength}
              boardId={boardId}
              cardDetails={cardDetails || undefined}
              boards={allBoardsVar}
              setBoardId={setBoardId}
              boardIdFromCardDetails={cardDetails?.boardId}
            />
           )} 
          </div>

          <div className='col-12 my-2'>
            <label
              className={`${
                theme === 'dark' ? 'white__text' : 'text-dark'
              } form-label fs-6 fw-bolder `}
            >
              Column
            </label>
            {isColumnLoading ? (
            <Skeleton count={1} height={40} />
          ) :  (
            <SelectColumn
              role={boardPerson?.role}
              boardId={boardId}
              cardDetails={cardDetails || undefined}
              columns={columns}
              columnId={columnId}
              setColumnId={setColumnId}
            />)}
          </div>
        </div>
        <div className={isHomeSearching ? ' my-2' : `fv-row mb-3 col-12 my-2`}>
          <label
            className={`form-label fs-6 fw-bolder  ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            }`}
          >
            Due Date & Time
          </label>
          <div className={`w-100  ${theme === 'dark' ? 'react_date_dark' : ''}`}>
            <CustomDatePicker
              role={boardPerson?.role}
              cardDetails={cardDetails}
              setStartDate={setStartDate}
              startDate={startDate}
              isPlaceholder={true}
              className={isHomeSearching ? 'w-100' : ''}
            />
          </div>
        </div>
        <div
          ref={reminderMainRef}
          className='fv-row mb-3 my-2 d-flex justify-content-between align-items-center'
        >
          <label
            className={`form-label fs-6 fw-bolder  ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            }`}
          >
            Reminder
          </label>
          <div className='d-flex justify-content-end' style={{width: '100%'}}>
            <Remainder
              cardDetails={cardDetails}
              reminders={reminders}
              setReminders={setReminders}
              isDisable={startDate}
              setDeletedReminderIds={setDeletedReminderIds}
              parentWidth={reminderMainWidth}
            />
          </div>
        </div>

        <div className='mb-3 row'>
          <label
            className={`col-sm-4 col-form-label ${theme === 'dark' ? 'white__text' : 'text-dark'}`}
          >
            Status
          </label>
          <div className='col-sm-8'>
            <select
              disabled={boardPerson?.role === 'Guest'}
              {...formik.getFieldProps('status')}
              className={clsx(
                `form-control form-control-lg form-control-solid  ${theme === 'dark' ? 'dark__theme__input' : ''}`,
                {'is-invalid': formik.touched.status && formik.errors.status},
                {
                  'is-valid': formik.touched.status && !formik.errors.status,
                }
              )}
            >
              <option value='' disabled>
                Select
              </option>
              <option value='todo'>To Do</option>
              <option value='in-progress'>In Progress</option>
              <option value='done'>Done</option>
            </select>
            {formik.touched.status && formik.errors.status && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger'>
                  {formik.errors.status}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className='mb-3 row'>
          <label
            className={`col-sm-4 col-form-label ${theme === 'dark' ? 'white__text' : 'text-dark'}`}
          >
            Priority
          </label>
          <div className='col-sm-8'>
            <select
              disabled={boardPerson?.role === 'Guest'}
              {...formik.getFieldProps('priority')}
              className={clsx(
                `form-control form-control-lg form-control-solid  ${theme === 'dark' ? 'dark__theme__input__no__color' : ''} `,
                {'is-invalid': formik.touched.priority && formik.errors.priority},
                {
                  'is-valid': formik.touched.priority && !formik.errors.priority,
                }
              )}
              style={{color: getOptionColor(formik.values.priority,theme)}}
            >
              <option className={theme === 'dark' ? 'label-color' : ''} value={undefined}>Select</option>
              {['Critical', 'High', 'Medium', 'Low', 'Future'].map((option, index) => (
                <option key={index} value={option} style={{color: getOptionColor(option,theme)}}>
                  {option}
                </option>
              ))}
            </select>
            {formik.touched.priority && formik.errors.priority && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger'>
                  {formik.errors.priority}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className='my-5 col-md-12 col-lg-8 col-sm-12 comments-section-small-scr'>
          <h6 className={`my-3 mt-5 ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>
            Comments
          </h6>
          <Comments
            ref={childCommentRef}
            optionsForSendAlert={optionsForSendAlert}
            setSendAlertToComment={setSendAlertToComment}
            sendAlertToComment={sendAlertToComment}
            sendAlertToBoardPerson={sendAlertToBoardPerson}
            cardDetails={cardDetails}
            boardPersonsForSendAlert={boardPersonsForSendAlert}
            setEditorComment={setEditorComment}
            editorComment={editorComment}
            sendAlertMsgComment={sendAlertMsgComment}
            handleFormSubmission={formik.handleSubmit}
            role={boardPerson?.role}
          />
        </div>
      </div>

      {/* <div className='row d-flex justify-content-between mt-4 mb-10'>
                <div className='col-md-6 col-lg-6 col-sm-12'>
                    <h6>
                        Assigned to:{' '}
                        <AssignTo options={options} setSelected={setSelected} selected={selected} />
                    </h6>
                </div> */}
      {/* <div className='col-md-4 col-lg-4 col-sm-12'>
                    <div className='d-flex justify-content-end mt-6'>
                        <Button
                            disabled={(boardId.length > 0 && columnId) || disableBtn ? false : true}
                            className={`${disableBtn && 'btn-custom-secondary'} w-75`}
                            type='submit'
                        >
                            {disableBtn ? <Spinner animation='border' variant='light' /> : 'Save'}
                        </Button>
                    </div>
                </div> */}
      {/* </div> */}
    </form>
  )
}

export default UpdateCardForm
