import React from 'react'
import { useAuth } from '../../../modules/auth'
import { useTheme } from '../../../context/ThemeContext'
type Props = {
  setCardModalShow: React.Dispatch<React.SetStateAction<boolean>>
  setCardPositionStatus: React.Dispatch<React.SetStateAction<string>>
  columnId: any;
  role : string
}

const AddCardFromDownBtn: React.FC<Props> = ({
  setCardModalShow,
  setCardPositionStatus,
  role,

}: Props) => {
  const { currentUser } = useAuth()
  const { role : checkRole } = currentUser?.data.user
  const {theme} = useTheme()

  return (
    <div className={`py-0 px-6 ${theme === 'dark' ? 'theme__div' : 'bg-secondary'}`} 
    style={{
      cursor: checkRole?.isGuest ? 'not-allowed' : 'pointer',
    }}
    >
     
        <button
        disabled = {role === 'Guest'}
          onClick={() => {
            setCardModalShow(true)
            setCardPositionStatus('down')
          }}
          type='button'
          className={`btn px-2 fs-6 text-muted w-100`}
        >
          Add a card
        </button>
    </div>
  )
}

export default AddCardFromDownBtn
