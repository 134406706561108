import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  boardTags: null,
}

export const boardTagsSlice = createSlice({
  name: 'boardTags',
  initialState,
  reducers: {
    setBoardTags: (state, action) => {
      state.boardTags = action.payload
    },
    addBoardTags: (state, action) => {
      action.payload.forEach((newTag: any) => {
        const existingTag = state.boardTags.find((tag: any) => tag.value === newTag.value);
    
        if (existingTag) {
          const currentCount = Number(existingTag.count || 0);
          existingTag.count = (currentCount + 1).toString();
        } else {
          state.boardTags = state.boardTags
            ? [...state.boardTags, { ...newTag, count: "1" }]
            : [{ ...newTag, count: "1" }];
        }
      });
    },
    

    removeBoardTags: (state, action) => {
      action.payload.forEach((removeTag: any) => {
        const existingTag = state.boardTags.find((tag: any) => tag.value === removeTag);
    
        if (existingTag) {
          const currentCount = Number(existingTag.count || 0);
    
          if (currentCount > 1) {
            existingTag.count = (currentCount - 1).toString();
          } else {
            state.boardTags = state.boardTags?.filter((tag: any) => tag.value !== removeTag);
          }
        }
      });
    },
    
  },
})

export const { setBoardTags, addBoardTags, removeBoardTags } = boardTagsSlice.actions

export default boardTagsSlice.reducer
