import React, {useEffect, useState} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {useDispatch, useSelector} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {setIsCardUpdated} from '../../store/slice/last-update-slice'
import {fetchAllCardsofLoggedInUser, fetchAllCardByBoardId} from '../../store/slice/card-slice'
import {awsFileUploadConfig} from '../../config/awsFileUpload'
import {
  useFileUploader,
  // useGetAllBoards,
  useGetColumns,
  useBoardPersons,
  useWebSocketHook,
} from '../../hooks'
import {getTimeDateFormate} from '../../helper-functions/TimeFormate'
import {countCardNumbers, findAndAddCardPosition} from '../../helper-functions/findCardNumAndPos'
import ReactS3Client from 'react-aws-s3-typescript'
import AddCardForm from '../Forms/AddCardForm'
import {createCard} from '../../services/cards'
import * as Yup from 'yup'
import {webSocketOperations} from '../../constants'
import {capitalizeEachWord} from '../../helper-functions/CapitalizeName'
import useSendAlert from '../../hooks/useSendAlert'
import {removeNullFromReminder} from '../../helper-functions/RemoveNullFromReminders'
import '../../styles/updateanaddform.css'
import {useTheme} from '../../context/ThemeContext'

const cardSchema = Yup.object().shape({
  cardName: Yup.string()
    .min(3, 'Minimum 3 Characters')
    .max(300, 'Maximum 300 Characters')
    .required('Card Name is Required')
    .trim(),
  status: Yup.string().required('Status is Required'),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AddNewCard = ({
  cardPositionStatus,
  columnTitle,
  columnIdProp,
  cardTitle,
  cardModalShow,
  setCardModalShow,
  cards,
  setCards,
}: any): React.JSX.Element => {
  const {theme} = useTheme()
  const params: any = useParams()
  const [editorDescriptionState, setEditorDescriptionState] = useState<any>(null)
  const [disableBtn, setDisableBtn] = useState(false)
  const [boardId, setBoardId] = useState(params.boardId)
  const [columnId, setColumnId] = useState('')
  const [startDate, setStartDate] = useState<any>(null)
  // const [isSendAlert, setIsSendAlert] = useState(false)
  const {currentUser} = useAuth()
  const {id: userId, fullName} = currentUser?.data.user
  const dispatch: Dispatch<any> = useDispatch()
  const {allBoards} = useSelector((state: any) => state.allBoardsReducer)

  const isLastUpdated = useSelector((state: any) => state.lastUpdateReducer)
  const {isCardUpdated} = isLastUpdated.isCardUpdated

  const boardFromReducer = useSelector((state: any) => state.boardReducer)
  const {data: boards} = boardFromReducer?.entities
  const {sendJsonMessage}: any = useWebSocketHook()
  const s3 = new ReactS3Client({
    ...awsFileUploadConfig,
    dirName: `cards-attachments/${process.env.REACT_APP_ENVIRONMENT}`,
  })
  // const {allBoards} = useGetAllBoards()
  const {selectedFile, selectFileHandler, deleteFileHandler, fileErrorMsg, setFileErrorMsg} =
    useFileUploader()
  const [reminders, setReminders] = useState<any>([])
  const board = boards?.filter((item: any) => params.boardId === item.board.id)
  const {columnResponse: columns, fetchColumnHandler} = useGetColumns(boardId || params.boardId)
  const {
    boardPersonsForSendAlert,
    optionsOfAssignee,
    optionsForSendAlert,
    selectAutoAssignee,
    setSelectAutoAssignee,
    getBoardPersonHandler,
  } = useBoardPersons()

  const {sendAlertTo, setSendAlertTo, sendAlertMsg, setSendAlertMsg, sendAlertToBoardPerson} =
    useSendAlert(boardPersonsForSendAlert, undefined, boardId, undefined, editorDescriptionState)

  // let {
  //     showVideo,
  //     setShowVideo,
  //     video,
  //     setVideo,
  //     isVideoSrcGet,
  //     setIsVideoSrcGet,
  //     imageSrc,
  //     setImageSrc,
  //     show,
  //     setShow,
  //     editor,
  //     setEditor,
  // } = useUImageVideoPreview()
  const initialValues = {
    cardName: '',
    dueDate: '',
    dueTime: '',
    boardName: board?.length > 0 ? board[0]?.board?.name?.trim() : '',
    columnName: columnTitle,
    status: 'todo',
    priority: undefined,
  }

  const handleFileUpload = async () => {
    const urls = []
    for (let index = 0; index < selectedFile?.length; index++) {
      const filename = selectedFile[index].name
      if (filename) {
        const filenameUpdated = filename?.substring(0, filename?.lastIndexOf('.'))
        try {
          const uploadFile: any = await s3.uploadFile(selectedFile[index], filenameUpdated)
          urls.push(uploadFile.location)
        } catch (error) {
          return error
        }
      }
    }
    return urls
  }

  const columnScrollHandler = () => {
    setTimeout(() => {
      if (cardPositionStatus === 'down') {
        const elements = document.getElementsByClassName('custom-class1')
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i]
          const droppableId = element.getAttribute('data-rbd-droppable-id')
          if (droppableId === columnId) {
            element.scrollTop = element?.scrollHeight
          }
        }
      } else {
        const elements = document.getElementsByClassName('custom-class1')
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i]
          const droppableId = element.getAttribute('data-rbd-droppable-id')
          if (droppableId === columnId) {
            element.scrollTop = 0
          }
        }
      }
    }, 1500)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: cardSchema,
    enableReinitialize: true,
    onSubmit: async (values, {resetForm}) => {
      setDisableBtn(true)
      const fileLinks = await handleFileUpload()
      const {cardLength, cardNumber} = await countCardNumbers(boardId, userId)
      const {utcDate, utcTime}: any = getTimeDateFormate(startDate)
      const position = findAndAddCardPosition(cardLength, cardPositionStatus)
      const postData = {
        created_by: fullName,
        last_modified_by: fullName,
        board_name: allBoards?.find((item: any) => item?.board?.id === boardId)?.board?.name?.trim(),
        card_name: values.cardName,
        description: editorDescriptionState,
        due_date: startDate === null ? null : utcDate,
        due_time: startDate === null ? null : utcTime,
        number: `${cardLength === undefined ? 1 : Number(cardNumber) + 1}`,
        position,
        priority: values.priority === undefined ? null : values.priority,
        rag: null,
        status: values.status,
        version: 1,
        applicationId: null,
        boardId: boardId,
        board_column_id: columnId,
        categoryId: null,
        componentId: null,
        customerId: null,
        productId: null,
        typeId: null,
        reporter: fullName,
        cardType: 'individual',
        organizationId: null,
        assignees:
          optionsOfAssignee.length > 0
            ? selectAutoAssignee.map((item: any) => {
                return {
                  board_person_id: item.value,
                }
              })
            : [],
        file_attachments: fileLinks,
        reminders: removeNullFromReminder(reminders as any),
      }

      try {
        const res = await createCard(postData, userId)
        const newCard = res.data.data
        setCards([...cards, newCard])
        const webSocketPayload: any = {
          event: 'publish',
          channel: boardId,
          userId,
          command: 'CREATE',
          operation: webSocketOperations.cardCreate,
          payload: {
            newCard,
            boardId,
            alert: `${capitalizeEachWord(fullName)} created a new card ${newCard.board_name}- ${
              newCard.number
            }.`,
          },
        }
        sendJsonMessage(webSocketPayload)
        dispatch(setIsCardUpdated({isCardUpdated: !isCardUpdated}))
        dispatch(fetchAllCardsofLoggedInUser())
        dispatch(fetchAllCardByBoardId({boardId: boardId, userId: userId}))

        if (sendAlertTo.length > 0 && editorDescriptionState !== null)
          sendAlertToBoardPerson(newCard, sendAlertTo, 'card-description')

        // if(!isSendAlert){
        setCardModalShow(false)
        resetForm()
        // }

        columnScrollHandler()
      } catch (error) {
        return error
      }

      setTimeout(() => {
        setDisableBtn(false)
        // setIsSendAlert(false)
        // setSendAlertMsg("")
      }, 4000)
    },
  })

  useEffect(() => {
    if (boardId) {
      fetchColumnHandler(boardId)
      getBoardPersonHandler(boardId)
    }
    //eslint-disable-next-line
  }, [boardId])

  return (
    <>
      <Modal
        show={cardModalShow}
        contentClassName={theme === 'dark' ? 'theme__div' : ''}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        animation={false}
        onHide={() => {
          setCardModalShow(false)
          formik.resetForm()
        }}
        scrollable
        className='modal-backdrop.show'
      >
        <div className='row d-flex justify-content-between border-bottom fs-2 fw-bold p-8'>
          <div className={`col-6 ${theme === 'dark' ? 'white__text' : ''}`}>{cardTitle}</div>
          <div className='col-4 text-end'>
            <Button
              disabled={!allBoards?.length || !boardId?.length || !columnId  || disableBtn}
              className={`${disableBtn && 'btn-custom-secondary '} btn-sm w-75`}
              onClick={() => {
                formik.handleSubmit()
              }}
            >
              {disableBtn ? <Spinner animation='border' variant='primary' /> : 'Save'}
            </Button>
          </div>
          <div className='col-1 text-end'>
            <span
              className='text-center p-2 cursor-pointer d-inline-block pb-0'
              onClick={() => setCardModalShow(false)}
            >
              X
            </span>
          </div>
        </div>
        <Modal.Body>
          <div className='container'>
            <AddCardForm
              formik={formik}
              allBoardsVar={allBoards}
              setBoardId={setBoardId}
              boardId={boardId}
              columnTitle={columnTitle}
              columnIdProp={columnIdProp}
              columns={columns}
              setColumnId={setColumnId}
              columnId={columnId}
              selectedFile={selectedFile}
              deleteFileHandler={deleteFileHandler}
              changeHandler={selectFileHandler}
              setStartDate={setStartDate}
              startDate={startDate}
              options={optionsOfAssignee}
              setSelected={setSelectAutoAssignee}
              selected={selectAutoAssignee}
              disableBtn={disableBtn}
              board={board}
              fileErrorMsg={fileErrorMsg}
              setFileErrorMsg={setFileErrorMsg}
              setEditor={setEditorDescriptionState}
              editor={editorDescriptionState}
              optionsForSendAlert={optionsForSendAlert}
              sendAlertMsg={sendAlertMsg}
              setSendAlertTo={setSendAlertTo}
              sendAlertTo={sendAlertTo}
              sendAlertToBoardPerson={sendAlertToBoardPerson}
              setSendAlertMsg={setSendAlertMsg}
              reminders={reminders}
              setReminders={setReminders}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddNewCard
