import Select from 'react-select'
import {useTheme} from '../../context/ThemeContext'
import { useAuth } from '../../modules/auth'
// import { useAuth } from '../../../app/modules/auth'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AssignTo = ({options, setSelected, selected, role,page}: any) => {
  const {theme: themeDark} = useTheme()
  const { currentUser } = useAuth()
    const { fullName } = currentUser?.data.user

  const colourStyles: any = {
    control: (styles: any) => ({...styles, backgroundColor: themeDark === 'dark' ? '' : 'white'}),
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: themeDark === 'dark' ? '#141520' : '#E6E6E6',
      }
    },
    multiValueLabel: (styles: any, {data}: any) => ({
      ...styles,
      backgroundColor: data.isDisabled ? '#FF677E' : '',
      color: data.isDisabled ? 'white' : '',
    }),
    multiValueRemove: (styles: any, {data}: any) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: '#FF677E',
        color: 'white',
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      overflow: 'auto',
      // maxHeight: '60px',
      height: page ? '100px' : '38px', 
      maxHeight: page ? '100px' : '60px', 
      alignItems: page ? 'flex-start' : 'center',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: themeDark === 'dark' ? '#FF7C00' : 'hsl(0, 0%, 80%)',
    }),
  }
  return (
    <>
      <Select
        isDisabled={role === 'Guest'}
        value={selected}
        isMulti
        options={options}
        className='basic-multi-select'
        classNamePrefix='select'
        styles={colourStyles}
        onChange={(e: any) => {
          if (page) {
            const adminItems = selected.filter((item: any) => item.isAdmin || item.label === fullName); 
            const nonAdminItems = e.filter((item: any) => !item.isAdmin);
            setSelected([...adminItems, ...nonAdminItems]); 
          } else {
            setSelected(e); 
          }
        }}
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,

            neutral0: themeDark === 'dark' ? '#1d1e2c' : theme.colors.neutral0,
            neutral80: themeDark === 'dark' ? '#F4FFFD' : theme.colors.neutral80,
            primary25: themeDark === 'dark' ? '#141520' : theme.colors.primary25,
          },
        })}
      />
    </>
  )
}
export default AssignTo
