import React,{ useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { profileRecoveryVerification } from '../../../services/delete-profile'
import { verify2fa } from '../../../services/two-factor-authentication'
import GeneralModal from '../../../components/Modals/GeneralModal'
import VerifyTwoFACodeModal from '../../../components/Modals/VerifyTwoFACodeModal'
import LoginForm from '../../../components/Forms/LoginForm'
import * as Yup from 'yup'
import AccountLockModal from '../../../components/Modals/AccountLockModal'
import { setIsSubscription } from '../../../store/slice/subscription-slice'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { createDatabase } from '../../../helper-functions/CreateIndexDb'

//eslint-disable-next-line
// const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required'),
  // .min(3, 'Minimum 3 symbols')
  // .max(50, 'Maximum 50 symbols')
  password: Yup.string()
    .required('Password is required')
  // .matches(passwordReg, 'Use 8 or more characters with a mix of letters, numbers & symbols (!,@,#,$,%,^,&,*).'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login(): React.JSX.Element {

  const [accountLockMsg, setAccountLockMsg] = useState("")
  const [show, setShow] = useState(false)
  const [show2faModal, setShow2faModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [recoverLoading, setRecoverLoading] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [errorMsg2fa, setErrorMsg2fa] = useState('')
  const [code, setCode] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [recoveryEmail, setRecoveryEmail] = useState('')
  const [verificationMessage, setVerificationMessage] = useState('')
  const [accountLock, setAccountLock] = useState(false)
  const [authData, setAuthData] = useState({})
  const { saveAuth, setCurrentUser } = useAuth()
  const dispatch: Dispatch<any> = useDispatch()

  const handleCloseVerifyCodeModal = () => setShow2faModal(false)
  const handleClose = () => {setShow(false)
    setRecoveryEmail('')}
  const handleShow = () => {setShow(true)}

  const profileRecoveryHandler = async () => {
    try {
      setRecoverLoading(true)
      const res = await profileRecoveryVerification({ email: recoveryEmail })
      setVerificationMessage(res.data.message)
      setTimeout(() => {
        handleClose()
      setRecoveryEmail('')
    }, 5000);
    } catch (error:any) {
      setVerificationMessage(error.response?.data?.message)
      return error
    }finally{
      setTimeout(() => {
      setRecoverLoading(false)
      }, 5000);
    }
  }

  const verifyCodeHandler = async () => {
    const msg =
      'Your attempt at 2-step verification was unsuccessful. Please ensure that you enter the correct code next time.'
    try {
      const { data: auth } = await verify2fa({
        code: code,
        email: emailValue,
        b4igoSecret: process.env.REACT_APP_B4IGO_SECRET,
      })

      if (auth.success) {
        saveAuth(auth)
        localStorage.setItem('auth', JSON.stringify(auth))
        setCurrentUser(auth)
      } else {
        setErrorMsg2fa(msg)
      }
    } catch (error) {
      setErrorMsg2fa(msg)
      return error
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true)
      setLoading(true)
      setEmailValue(values.email)
      const auth: any = localStorage.getItem("auth")
      const authData = JSON.parse(auth)
      if (authData) {
        return
      }

      try {
        const { data: auth }: any = await login(values.email, values.password)
        setAuthData(auth.data)
        if (auth.token && auth.data.user.accessEnter) {
          setLoading(false)
          // gesut-work functionaity
          // const authData = {...auth , userRole: "Guest"}
          // saveAuth(authData)
          // setCurrentUser(authData)

          createDatabase()
          saveAuth(auth)
          setCurrentUser(auth)
      

          }else{
            setAccountLock(true)
          }
          if(auth.data.user.subscription.isExpire){
            dispatch(setIsSubscription({ isSubscription: 'cancel' }))
          }else{
            dispatch(setIsSubscription({ isSubscription: 'active' }))
          }   
      } catch (error: any) {
      
        if (error.response.data.message.includes('temporarily deactivated')) {
          handleShow()
        }
        if (error.response.data.message.includes("Please reset")) {
          setAccountLockMsg(error.response.data.message)
        }else{
        setErrorMsg(error.response.data.message)
        }
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    const alreadyLoginHandler = () => {
      const auth: any = localStorage.getItem("auth")
      const authData = JSON.parse(auth)

      if (authData) {
        document.location.reload()
      }
    }
    const closeInterval = setInterval(alreadyLoginHandler, 3000)
    return () => clearInterval(closeInterval)
  }, [])




  return (
    <>
      <LoginForm
        formik={formik}
        handleSubmit={formik.handleSubmit}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        accountLockMsg={accountLockMsg}
        setAccountLockMsg={setAccountLockMsg}
        loading={loading}
      />

      <GeneralModal
        showModal={show}
        handleCloseModal={handleClose}
        modalTitle='Account Deactivated'
        modalBodyMsg='Your account is temporarily deactivated. Want to recover?'
        inputLabel='Enter your email.'
        inputType='email'
        errorMsg={verificationMessage}
        setErrorMsg={setVerificationMessage}
        btnConfirmTitle='Recover'
        btnCloseTitle='No'
        inputPlaceholder='Email'
        onConfirmation={profileRecoveryHandler}
        inputValue={recoveryEmail}
        loading={recoverLoading}
        setValue={setRecoveryEmail}
      />

      <VerifyTwoFACodeModal
        showModal={show2faModal}
        handleCloseModal={handleCloseVerifyCodeModal}
        code={code}
        setCode={setCode}
        errorMsg2fa={errorMsg2fa}
        handleSubmit={formik.handleSubmit}
        verifyCodeHandler={verifyCodeHandler}
        btnVerifyTitle="Login"
      />

      <AccountLockModal accountLock={accountLock} handleClose={() => setAccountLock(false)} userData={authData} />
    </>
  )
}
