import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {updateBoardNameInAllCards} from '../../../services/cards'
import {changeBoardStatus, updateBoardName} from '../../../services/boards'
import {fetchBoardByUserId} from '../../../store/slice/board-slice'
import {useAuth} from '../../../modules/auth'
import {fetchArchivedBoardByUserId} from '../../../store/slice/archived-board'
import UpdateBoardTitle from '../UpdateBoardTitle'
import {webSocketOperations} from '../../../constants'
import {capitalizeEachWord} from '../../../helper-functions/CapitalizeName'


import '../../../styles/boardLists.css'
import { useTheme } from '../../../context/ThemeContext'
import { fetchAllBoards } from '../../../store/slice/all-boards-slice'
import DefaultBoardModal from '../../Modals/DefaultBoardModal'
import { useSelector } from 'react-redux'

type Props = {
  isAdmin: boolean
  boardId: string
  image: string
  title: string
  time: string
  className: string
  sendJsonMessage: any
  backgroundImage: string
  textColor: string
  status?: string
  isOwner?: boolean
  page?: number
  isHomeSearching? : boolean
  getUpdatedTitle?: (title : string)=> void
}

const BoardsList: React.FC<Props> = ({
  sendJsonMessage,
  isAdmin,
  boardId,
  textColor,
  isOwner,
  status,
  className,
  title,
  time,
  backgroundImage,
  page,
  isHomeSearching,
  getUpdatedTitle
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [defaultBoardError, setDefaultBoardError] = useState(false)
  const {theme} = useTheme()

  const [boardTitle, setBoardTitle] = useState(title.trim())
  const [newTitle, setNewTitle] = useState('')
  const [boardIdState, setBoardIdState] = useState('')
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {id, fullName, role} = currentUser?.data.user
  const { allBoards } = useSelector((state: any) => state.allBoardsReducer)

  //guest Mode // hide design button

  const dispatch: Dispatch<any> = useDispatch()
  const isOtherBoards = window.location.href.includes('other-boards')  

  const archivedBoardHandler = async (e: any) => {

    const matchedBoard = allBoards?.find((board: any) => board.board.id === boardId!);
    if(matchedBoard.board.default && e.target.value === 'archived'){
      setDefaultBoardError(true)
      return
    }else{
      try {
        await changeBoardStatus(e.target.value, boardId!)
  
        dispatch(fetchBoardByUserId({userId: id, isAdmin: true , page: page}))
        dispatch(fetchArchivedBoardByUserId({userId: id}))
        dispatch(fetchAllBoards(id))

        const webSocketPayload: any = {
          event: 'publish',
          channel: boardId,
          userId: id,
          command: 'UPDATE',
          operation: webSocketOperations.boardStatusUpdate,
          payload: {
            status: e.target.value,
            boardId,
            alert: `${capitalizeEachWord(fullName)} changed board ${title} status to "Archived".`,
          },
        }
        sendJsonMessage(webSocketPayload)
      } catch (error) {
        return error
      }
    }
   
  }

  const updateBoardTitleHandler = async () => {
    setNewTitle(boardTitle)
    setIsEditable(false)
    if (boardTitle !== (title?.trim() || '') && boardTitle !== '' && boardTitle.length >= 3) {
      try {
        await updateBoardName({name: boardTitle}, boardIdState, id)
        setNewTitle(boardTitle)
        const webSocketPayload: any = {
          event: 'publish',
          channel: boardIdState,
          userId: id,
          command: 'UPDATE',
          operation: webSocketOperations.boardNameUpdate,
          payload: {
            newBoardTitle: boardTitle,
            boardId: boardIdState,
            alert: `${capitalizeEachWord(fullName)} changed board name ${title} to ${boardTitle}.`,
          },
        }
        sendJsonMessage(webSocketPayload)
        await updateBoardNameInAllCards({
          board_name: boardTitle,
          boardId: boardIdState,
        })
        dispatch(fetchAllBoards(id))
        if(isHomeSearching && getUpdatedTitle){
          getUpdatedTitle(boardTitle)
        }
      } catch (error) {
        return error
      } finally {
        dispatch(fetchBoardByUserId({userId: id, isAdmin: true ,page: page}))
      }
    } else {
      setBoardTitle(title?.trim() || '')
    }
  }

  useEffect(() => {
    setBoardTitle(title?.trim() || '')
  }, [title])

  return (
    <>
    {defaultBoardError && (<DefaultBoardModal boardTitle={boardTitle} defaultBoardError={defaultBoardError} setDefaultBoardError={setDefaultBoardError} />)}
    <div
      className={`card bgi-no-repeat ${className} ${
        backgroundImage ? 'board-background-image' : 'border-no-background-image'
      }  ${(theme === 'dark' && !backgroundImage )? 'theme__div' : ''}`}
      style={{
        backgroundImage: `url(${
          backgroundImage
        })`,
      }}
    >
      <div className={`card-body`}>
        <UpdateBoardTitle
          setIsEditable={setIsEditable}
          setBoardIdState={setBoardIdState}
          boardId={boardId}
          setBoardTitle={setBoardTitle}
          boardTitle={boardTitle}
          updateBoardTitleHandler={updateBoardTitleHandler}
          isEditable={isEditable}
          newTitle={newTitle}
          title={title}
          isAdmin={isAdmin}
          textColor={textColor}
        />

        {backgroundImage ? (
          <div className='my-6'>
            <span className='fs-7' style={{color: textColor}}>
              Created On:
            </span>{' '}
            <span className='fw-bolder' style={{color: textColor}}>
              {time}
            </span>
          </div>
        ) : (
          <div className='text-muted fs-7 my-6'>
            Created On: <span className='fw-bolder text-primary'>{time}</span>
          </div>
        )}

        <div className='d-flex justify-content-between flex-wrap gap-2'>
          <div className='d-flex'>
             <button
                className='btn btn-primary p-2 me-1'
                type='button'
                onClick={() => navigate(`/notes/${boardId}`,{state:boardTitle})}
              >
                Notes
              </button>
            {/* Open board button */}
            <button
              className='btn btn-primary p-2 mx-1'
              type='button'
              onClick={() => navigate(`/board/${boardId}`)}
            >
              Tasks
            </button>
            {/* Design button */}
            {isAdmin && role?.isGuest !== true && (
              <div
                className='p-2 ms-1 d-flex'
                // type='button'
                onClick={() => navigate(`/board/${boardId}/design`)}
              >
                <i className={`fa fa-cog fs-1 cursor-pointer ${theme === 'dark' ? 'text-white': ''}`}></i>
              </div>
            )}
          </div>

          {isAdmin && role?.isGuest !== true && !isOtherBoards && (
            <div>
              <select
                style={{paddingBlock: 0}}
                disabled={!isOwner}
                value={status}
                onChange={archivedBoardHandler}
                className={`form-control form-select form-control-sm form-control-solid form-control ${theme === 'dark' ? 'dark__div' : ''}`}

              >
                <option value="hidden">Hidden</option>
                <option value="archived">Archived</option>
                <option value="active">Active</option>
              </select>
            </div>
          )}

          {/* Add to archived button */}
          {/* {!isOtherBoards && (
            <button className='btn-custom btn-custom-secondary p-2' type='button' onClick={archivedBoardHandler}>
              Add to Archived
            </button>
          )} */}
        </div>
      </div>
    </div>
    </>
  )
}



export {BoardsList}

