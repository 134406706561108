import React, {useState, useEffect} from 'react'
import EditNewCard from '../UpdateCard/EditBoardCard'
import 'react-loading-skeleton/dist/skeleton.css'

import {useEditModal} from '../../context/ShowEditModalQuill'

const LinkCardUpdate: React.FC = () => {
  const {card, showEditModalQuill, setShowEditModalQuill} = useEditModal()
 

  const [modalStates, setModalStates] = useState<{[key: string]: boolean}>({})

  useEffect(() => {
    
    if (showEditModalQuill === true && card) {
      setModalStates((prev) => ({...prev, [card.id]: true}))
      setShowEditModalQuill(false)
    }

    return () => {
     
      setShowEditModalQuill(false)
    }
  }, [showEditModalQuill, card, setShowEditModalQuill])

  const closeModal = (modalId: string) => {
    setModalStates((prev) => ({...prev, [modalId]: false}))
  }

  return (
    <>
      {Object.keys(modalStates).map(
        (modalId) =>
          modalStates[modalId] && (
            <EditNewCard
              key={modalId}
              cardTitle='Edit Card'
              isBoard={false}
              cardDetails={card}
              cardModalShow={modalStates[modalId]}
              setCardModalShow={() => closeModal(modalId)}
            />
          )
      )}
    </>
  )
}

export default LinkCardUpdate
