import React, {useState, useEffect} from 'react'
import {login, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import clsx from 'clsx'
import * as Yup from 'yup'
import {getAllRoles, userLookup} from '../../../services/user-lookup'
import {useAuth} from '../core/Auth'
import 'react-phone-input-2/lib/style.css'

import {useFormik} from 'formik'
import {isValidPhoneNumber} from 'libphonenumber-js'
import {useTogglePassword} from '../../../hooks'
import PhoneInput from 'react-phone-input-2'
import {decodeInvitation} from '../../../services/invitation'
import {createOrganizationMember} from '../../../services/company'
import Skeleton from 'react-loading-skeleton'
import {useDispatch, useSelector} from 'react-redux'
import {setSession} from '../../../store/slice/auth-slice'
import CustomAlert from '../../../components/CustomAlert'
import {useTheme} from '../../../context/ThemeContext'

//eslint-disable-next-line
const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@~\$%\^\*])(?=.{12,})/
// const nameReg = /^[a-zA-Z ._-]*$/
const registrationIndividualSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, 'Maximum 40 characters are allowed.')
    .required('Name is required')
    .trim(),
  // .matches(nameReg, 'Name should be in Upper or Lower case letters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .trim()
    .matches(
      passwordReg,
      'Please use 12 or more characters with a mix of letters, numbers and any of the following special characters: !,@,~,$,%,^ or *'
    ),
})

export function AcceptAndRegister(): React.JSX.Element {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [decodeLoading, setDecodeLoading] = useState(true)
  // const [ disableBtn,  setDisableBtn] = useState(true)
  // const [redirectToRegister, setRedirectToRegister] = useState(false)
  const [decodedTokenDetails, setDecodedTokenDetails] = useState<any>(null)
  const [popUpMsg, setPopUpMsg] = useState('')
  // const [userId, setUserId] = useState('')
  const [phoneState, setPhoneState] = useState('')
  const [errorMsgRegistration, setErrorMsgRegistration] = useState('')
  // const {emailVerificationHandler} = useEmailVerification()
  const {passwordType, togglePassword} = useTogglePassword()
  const [roleList, setRoleList] = useState<any>([])
  const {saveAuth, setCurrentUser} = useAuth()
  const {redirectUrl} = useSelector((state: any) => state.RedirectionReducer)

  const urlAddress = window.location.search
  const token = urlAddress.slice(7)
  const initialValues = {
    name: '',
    email: decodedTokenDetails?.email,
    password: '',
    image: null,
    roleId: '',
    boardName: null,
    pageName: null,
  }

  const navigate = useNavigate()
  const {theme} = useTheme()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationIndividualSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)

      const role = roleList.find((roles: any) => roles.name === 'Member')?.id
      values.roleId = role
      let validNumber = true
      if (phoneState.length > 0) {
        validNumber = isValidPhoneNumber('+' + phoneState)
      }
      if (validNumber) {
        const isInvitedByOrganization = true
        try {
          const {data: auth} = await register(
            values.boardName,
            values.pageName,
            values.email,
            values.name,
            values.password,
            phoneState.length > 0 ? phoneState : null,
            values.image,
            values.roleId,
            isInvitedByOrganization,
            'FreeTrial'
          )
          const memberPayload = {
            organizationLicenseId: decodedTokenDetails.organizationLicenseId,
            organizationId: decodedTokenDetails.organizationId,
            email: decodedTokenDetails.email,
            role: decodedTokenDetails.role,
            userId: auth.user.id,
          }

          await createOrganizationMember(memberPayload)
          setPopUpMsg('Registration Successful!')
          // emailVerificationHandler({
          //   userId: auth.user.id,
          //   fullName: values.name,
          //   email: decodedTokenDetails.email,
          // })

          setTimeout(() => {
            loginHandler(decodedTokenDetails.email, values.password)
          }, 5000)
          setLoading(false)
        } catch (error: any) {
          const msg = error.response.data.message
          setErrorMsgRegistration(msg)
        } finally {
          setSubmitting(false)
          setLoading(false)
          formik.resetForm()
        }
      } else {
        setPopUpMsg('Please Enter Valid Phone Number!')
      }
      setSubmitting(false)
      setLoading(false)
    },
  })

  const decodeTokenHandler = async () => {
    setDecodeLoading(true)

    try {
      const decodedResponse = await decodeInvitation({token, usage: 'boardInvite'})

      const decodedData = decodedResponse.data.decodeToken

      setDecodedTokenDetails(decodedData)

      try {
        const lookupResponse = await userLookup(decodedData.email)
        const userId = lookupResponse.data.user.id
        const memberPayload = {
          organizationLicenseId: decodedData.organizationLicenseId,
          organizationId: decodedData.organizationId,
          email: decodedData.email,
          role: decodedData.role,
          userId,
        }

        await createOrganizationMember(memberPayload)
        setPopUpMsg('Invitation Accepted!')
        setTimeout(() => {
          navigate('/auth')
        }, 3000)
        // setDisableBtn(false)
      } catch (error: any) {
        // if (error.response.status === 404) {
        //   setPopUpMsg("Create your account!")
        //   setTimeout(() => {
        //     setRedirectToRegister(true)
        //   }, 3000);
        // }
        return error
      } finally {
        setTimeout(() => {
          // setPopUpMsg("")
          setDecodeLoading(false)
        }, 3000)
      }
    } catch (error: any) {
      const msg = error.response.data.message
      if (msg === 'jwt expired') {
        navigate('/error/404')
      }
    }
  }
  // const acceptInvitationHandler = async () => {
  //   setDisableBtn(true)
  //   try {
  //     let memberPayload = {
  //       organizationLicenseId: decodedTokenDetails.organizationLicenseId,
  //       organizationId: decodedTokenDetails.organizationId,
  //       email: decodedTokenDetails.email,
  //       role: decodedTokenDetails.role,
  //       userId,
  //     }

  //     await createOrganizationMember(memberPayload)
  //     setPopUpMsg('Invitation Accepted!')
  //     setTimeout(() => {
  //       navigate('/auth')
  //     }, 3000)
  //   } catch (error:any) {
  //     let msg = error.response?.data?.message
  //     setPopUpMsg(msg)
  //     return error
  //   }finally{
  //     setTimeout(() => {
  //       setDisableBtn(false)
  //     }, 3000);
  //   }
  // }

  useEffect(() => {
    const auth: any = localStorage.getItem('auth')
    const authData = JSON.parse(auth)

    if (authData) {
      localStorage.clear()
      window.location.reload()
    }
  }, [])

  const getRolesHandler = async () => {
    try {
      const roleRes = await getAllRoles()
      setRoleList(roleRes.data.data)
    } catch (error) {
      return error
    }
  }

  const loginHandler = async (email: string, password: string) => {
    try {
      const {data: auth}: any = await login(email, password)
      setLoading(false)
      navigate(redirectUrl)
      saveAuth(auth)
      setCurrentUser(auth)
      dispatch(setSession(false))
    } catch (error: any) {
      return error
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    getRolesHandler()
    decodeTokenHandler()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      {/* {!redirectToRegister ? (
        <>
        
          <div className='mb-10 text-center'>
            <h1 className='text-dark mb-3'>Accept Organisation Invitation</h1>
          </div>

          <div className='text-center'>
            <button
              type='button'
              className='btn btn-lg btn-primary w-50 mb-5'
              onClick={acceptInvitationHandler}
              disabled={disableBtn}
            >
              Accept
            </button>

            {popUpMsg.length > 0 && (
              <Alert variant='info' className='my-3'>
                {popUpMsg}
              </Alert>
            )}
          </div>
        </>
      ) : ( */}
      <>
        {decodeLoading ? (
          <>
            <div className='mb-2 text-center'>
              <h1 className='text-dark'>
                <Skeleton count={1} height={35} />
              </h1>
            </div>
            <p className='text-muted my-3'>
              <Skeleton count={1} height={20} />
            </p>
            <div className='text-muted my-10'>
              <Skeleton count={1} height={45} />
            </div>
            <div className='text-muted my-10'>
              <Skeleton count={1} height={45} />
            </div>
            <div className='text-muted my-5'>
              <Skeleton count={1} height={40} />
            </div>
            <div className='text-muted my-3'>
              <Skeleton count={1} height={25} />
            </div>
          </>
        ) : (
          <>
            <div className='mb-2 text-center'>
              <h1 className={` ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>
                Sign Up to <span className='fw-semibold'>B4i</span>
                <span className='text-primary fw-semibold'>GO</span>
              </h1>
            </div>
            <p className='text-muted my-7'>
              {decodedTokenDetails?.organizationName} already gave us your email address, so we just
              need your name and for you to create a password. That&apos;s it!
            </p>

            <form
              className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
                theme === 'dark' ? 'theme__div' : ''
              }`}
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className={`row fv-row mt-5  ${theme === 'dark' ? 'theme__div' : ''}`}>
                <div className='col-xl-12 my-3'>
                  <label
                    className={`class="form-label fw-bold ${
                      theme === 'dark' ? 'white__text' : 'text-dark'
                    } fs-6`}
                  >
                    Please enter your name
                  </label>
                  <input
                    placeholder='Name'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      `form-control form-control-lg form-control-solid  ${
                        theme === 'dark' ? 'dark__theme__input' : ''
                      } italics-placeholder`,
                      {
                        'is-invalid': formik.touched.name && formik.errors.name,
                      },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.name}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-12 my-3'>
                  <label
                    className={`form-label fw-bold ${
                      theme === 'dark' ? 'white__text' : 'text-dark'
                    } fs-6 mb-0`}
                  >
                    Your email address
                  </label>
                  <input
                    disabled
                    placeholder='Email Address'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      `form-control form-control-lg form-control-solid  ${
                        theme === 'dark' ? 'dark__theme__input' : ''
                      } italics-placeholder`,
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='text-danger'>
                          {formik.errors.email as string}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-xl-12 my-3' data-kt-password-meter='true'>
                  <div className='mb-1'>
                    <label
                      className={`form-label fw-bold ${
                        theme === 'dark' ? 'white__text' : 'text-dark'
                      }  fs-6 mb-0`}
                    >
                      Phone (optional)
                    </label>
                    <div className='position-relative mb-3'>
                      <PhoneInput
                        placeholder='+44 7700 900077'
                        country='44'
                        value={phoneState}
                        onChange={(phone) => setPhoneState(phone)}
                        inputClass={`w-100 ${theme === 'dark' ? 'white__text' : ''}`}
                          dropdownClass={theme === 'dark' ? 'theme__div' : ''}
                          containerClass={theme === 'dark' ? 'react-tel-input-dark' : 'react-tel-input-light'}

                      />
                    </div>
                  </div>
                </div>

                <div className='col-xl-12 my-3'>
                  <label
                    className={`form-label fw-bold ${
                      theme === 'dark' ? 'white__text' : 'text-dark'
                    } fs-6 mb-0`}
                  >
                    Create a password
                  </label>
                  <div className='position-relative mb-3'>
                    <div className='input-group'>
                      <input
                        type={passwordType}
                        placeholder='Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          `form-control form-control-lg form-control-solid  ${
                            theme === 'dark' ? 'dark__theme__input' : ''
                          } italics-placeholder`,

                          {
                            'is-valid':
                              phoneState.length > 0 &&
                              formik.touched.password &&
                              !formik.errors.password,
                          }
                        )}
                      />

                      <div className='input-group-btn'>
                        <button
                          className={`${theme === 'dark' ? 'theme__div' : ''} btn border-end border-top border-bottom`}
                          style={{borderRadius: '0px 10px 10px 0px'}}
                          type='button'
                          onClick={togglePassword}
                        >
                          {passwordType === 'password' ? (
                            <i
                              className={`  bi bi-eye-slash  ${
                                theme === 'dark' ? 'white__text' : ''
                              }`}
                            ></i>
                          ) : (
                            <i className={`bi bi-eye ${theme === 'dark' ? 'white__text' : ''}`}></i>
                          )}
                        </button>
                      </div>
                    </div>

                    {
                      // phoneState.length > 0 &&
                      formik.touched.password && formik.errors.password ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='text-danger'>
                              {formik.errors.password}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert' className='' style={{color: 'gray'}}>
                              Please use 12 or more characters with a mix of letters, numbers and
                              any of the following special characters: !,@,~,$,%,^ or *
                            </span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className='my-3'>
                <CustomAlert
                  variant={'info'}
                  alertMessage={popUpMsg}
                  setAlertMessage={setPopUpMsg}
                />
              </div>

              <div className='my-3'>
                <CustomAlert
                  variant={'danger'}
                  alertMessage={errorMsgRegistration}
                  setAlertMessage={setErrorMsgRegistration}
                  time={5000}
                />
              </div>

              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Sign Up</span>}
                  {loading && <span className='indicator-label'>Loading...</span>}
                </button>
                <Link to='/auth'>
                  <button
                    type='button'
                    id='kt_login_signup_form_cancel_button'
                    className='btn btn-lg btn-secondary w-100 mb-5'
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </>
        )}
      </>
      {/* )} */}
    </>
  )
}
