import { configureStore } from '@reduxjs/toolkit'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from '@reduxjs/toolkit'
import boardSlice from './slice/board-slice'
import columnSlice from './slice/column-slice'
import cardSlice from './slice/card-slice'
import authSlice from './slice/auth-slice'
import homeCardSlice from './slice/home-card-slice'
import lastUpdateSlice from './slice/last-update-slice'
import otherBoardSlice from './slice/other-board-slice'
import ColumnPositionSlice from './slice/column-positions'
import BoardDesignSlice from './slice/board-design'
import archivedBoardSlice from './slice/archived-board'
import inviteSlice from './slice/invitation-accepted-slice'
import isRenderSlice from './slice/isRender-slice'
import allBoardsSlice from './slice/all-boards-slice'
import subscriptionSlice from './slice/subscription-slice'
import DoneFilterSlice from './slice/DoneFilter'
import paymentSlice from './slice/payment-slice'
import RedirectionSlice from './slice/redirect-slice'
import TotalLicenseSlice from './slice/total-license-slice'
import companyDetailSlice from './slice/company-details-slice'
import contactDetailSlice from './slice/contact-detail-slice'
import roleSlice from './slice/role-slice'
import homeSearchingSlice from './slice/home-searching-slice'
import organizationInvitationSlice from './slice/organization-accepted'
import { EncryptTransform } from "../../app/utils/reduxEncrypt"
import  boardTagsSlice  from './slice/board-tags-slice'
// import  from 'redux-persist-transform-encrypt';


const encryptor:any = EncryptTransform({
  secretKey: process.env.REACT_APP_PERSIST_SECRET as string,
  onError: function (error: any) {
    // eslint-disable-next-line no-console
    console.error('Redux Persist Encryptor Error:', error);
  },
});


const persistConfig ={
  key: 'root',
  storage,
  transforms: [encryptor]
};


const reducers = combineReducers({
  boardTagsReducer: boardTagsSlice,
  boardReducer: boardSlice,
  columnReducer: columnSlice,
  cardReducer: cardSlice,
  authReducer: authSlice,
  homeCardReducer: homeCardSlice,
  lastUpdateReducer: lastUpdateSlice,
  inviteReducer: inviteSlice,
  isRenderReducer: isRenderSlice,
  allBoardsReducer: allBoardsSlice,
  otherBoardReducer: otherBoardSlice,
  archivedBoardReducer: archivedBoardSlice,
  ColumnPositionReducer: ColumnPositionSlice,
  boardDesignReducer: BoardDesignSlice,
  subscriptionReducer: subscriptionSlice,
  DoneFilterReducer: DoneFilterSlice,
  paymentReducer: paymentSlice,
  RedirectionReducer: RedirectionSlice,
  TotalLicenseReducer: TotalLicenseSlice,
  PersonRoleReducer : roleSlice,
  // SwitchAccountReducer: SwitchAccountSlice,
  contactDetailReducer: contactDetailSlice,
  companyDetailReducer: companyDetailSlice,
  organizationInvitationReducer: organizationInvitationSlice,
  homeSearchingReducer : homeSearchingSlice
})

const persistedReducer = persistReducer(persistConfig, reducers)



export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENVIRONMENT === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
// export const useAppDispatch = () => useDispatch<AppDispatch>()
