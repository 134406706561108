/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import apiService from './index'

export const notificationSubscriber = async (data: any) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/push/notification/subscribers`,
    data
  )
}
export const markAsRead = async (data: any) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/push/notification/subscribers/messages/markAs`,
    data
  )
}
export const markAsReadAll = async (data: any) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/push/notification/subscribers/all-messages/markAs`,
    data
  )
}
export const sendPushAlert = async (data: any) => {
  return await apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/push/notification/trigger/event`,
    data
  )
}

export const getPushNotifications = async (subscriberId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL}/push/notification/${subscriberId}/feed`
  )
}
export const getPushNotificationsCount = async (subscriberId: string) => {
  return await apiService.get(
    `${process.env.REACT_APP_BACKEND_URL}/push/notification/subscribers/${subscriberId}/unseen`
  )
}

export const deletePushNotificationFeed = async (msgId: string) => {
  return await apiService.delete(`${process.env.REACT_APP_BACKEND_URL}/push/notification/feed/${msgId}`)
}
