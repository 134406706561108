import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllBoardByUserId} from '../../services/boards'

// First, create the thunk
export const fetchBoardByUserId : any= createAsyncThunk(
  'board/fetchBoardByUserId',
  async (data: any) => {
    try {
      const response = await getAllBoardByUserId(data.userId, data.isAdmin , data.limit , data.page)

      return response.data
    } catch (error: any) {
       return error
    }
  }
)

const initialState: any = {
  entities: {},
  
  loading: false,
  errors: {},
}

export const boardSlice = createSlice({
  name: 'fetchBoardByUserId',
  initialState,
  reducers: {
    setBoardsUpdate: (state, action) => {
      state.entities.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBoardByUserId.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchBoardByUserId.fulfilled, (state, action) => {
      state.entities = {...action.payload}

      state.loading = false
    })
    builder.addCase(fetchBoardByUserId.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
    })
  },
})
export const {setBoardsUpdate} = boardSlice.actions
export default boardSlice.reducer
