import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {setSelectedPlanDetails} from '../../../store/slice/payment-slice'
import {useDispatch, useSelector} from 'react-redux'
import {useTheme} from '../../../context/ThemeContext'

const PricingPlan = (): React.JSX.Element => {
  const {planDetails: planUserData} = useSelector((state: any) => state.paymentReducer)
  // const [isChecked, setIsChecked] = useState(planUserData?.type === 'Yearly' ? true : false)

  const [selectedType, setSelectedType] = useState(planUserData?.type);
  const [usersCount, setUsersCount] = useState(planUserData?.licenseEmails ? planUserData?.licenseEmails.totalLicense+1 : 1)
  const {theme} = useTheme()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleChange = (event:any) => {
    setSelectedType(event.target.value);
  };

  const handleUserCount = (event: any) => {
    setUsersCount(event.target.value)
  }

  const countPlan = () => {
    const amount =selectedType === 'Introductory' ? usersCount * 0.79 * 3  : selectedType === 'Monthly' ? usersCount * 5.99  : usersCount * 4.99 * 12
    // const amount = isChecked ? usersCount * 4.99 * 12 : usersCount * 5.99
    return '$' + amount.toFixed(2)
  }

  const buyNowHandler = () => {
    const amountToPay = countPlan()
    if (amountToPay !== '$0.00') {
      const data = {
        totalUser: usersCount,
        amountToPay,
        planType: selectedType,
      }
      dispatch(setSelectedPlanDetails({selectedPlanDetails: data}))
      navigate('/auth/payment')
    }
  }

  return (
    <div className='w-100'>
      <div className='row mt-15  d-flex justify-content-center'>
        <h1 className={`text-center fs-1 py-3 ${theme === 'dark' ? 'white__text' : ''}`}>
          Pricing Plan
        </h1>

        <div className='col-12'>
          <div className='d-flex justify-content-center align-items-center p-3 border rounded'>
          <div className='my-3 d-flex align-items-center justify-content-start gap-12'>
                <label className='form-check-label'>
                  <input
                    type='radio'
                    // disabled={planUserData.type === 'Monthly'}
                    value='Introductory'
                    className='form-check-input mr-2'
                    checked={selectedType === 'Introductory'}
                    onChange={handleChange}
                  />
                  <span className='text-gray-600 fw-bold' style={{marginLeft: '5px'}}>
                    Introductory
                  </span>
                </label>
                <label className='form-check-label'>
                  <input
                    type='radio'
                    // {...formik.getFieldProps('role')}
                    value='Monthly'
                    className='form-check-input mr-2'
                    // disabled={planUserData.type === 'Introductory'}
                    checked={selectedType === 'Monthly'}
                    onChange={handleChange}
                  />
                  <span className='text-gray-600 fw-bold' style={{marginLeft: '5px'}}>
                    Monthly
                  </span>
                </label>
                <label className='form-check-label'>
                  <input
                    type='radio'
                    value='Yearly'
                    className='form-check-input mr-2'
                    onChange={handleChange}
                    checked={selectedType === 'Yearly'}
                  />
                  <span className='text-gray-600 fw-bold' style={{marginLeft: '5px'}}>
                    Annually
                  </span>
                </label>
                </div>
            {/* <h3 className={`d-inline-block mx-2 ${theme === 'dark' ? 'white__text' : ''}`}>
              Monthly
            </h3> */}
            {/* <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'></label>
            </div> */}
            {/* <h3 className={`d-inline-block ${theme === 'dark' ? 'white__text' : ''}`}>Yearly</h3> */}
          </div>
          <div className='mb-6 p-3 border'>
            <div className='d-flex justify-content-between align-items-center px-3 mb-2 mt-3'>
              <h4 className={`${theme === 'dark' ? 'white__text' : ''} d-inline-block`}>
                Numbers of Users
              </h4>
              <input
                disabled
                min={1}
                value={usersCount}
                onChange={handleUserCount}
                type='number'
                className={`form form-control d-inline-block w-25 ms-5 p-2 ${
                  theme === 'dark' ? 'dark__theme__input' : ''
                }`}
              />
            </div>
            <hr />
            <div className='d-flex justify-content-between align-items-center px-3 mb-2 mt-3'>
              <h4 className={theme === 'dark' ? 'white__text' : ''}>
                {' '}
                {/* {!isChecked ? '$5.99/month per user' : '$4.99 * 12/year per user'} */}
                {selectedType === 'Introductory' ? '$0.79/month per user' : selectedType === 'Monthly' ?'$5.99/month per user': '$4.99 * 12/year per user'} 
              </h4>

              <h4 className={theme === 'dark' ? 'white__text' : ''}>{countPlan()}</h4>
            </div>

            <hr />
            <div className='d-flex justify-content-between align-items-center px-3 my-2'>
              <h4 className={theme === 'dark' ? 'white__text' : ''}> Total</h4>
              <h4 className={theme === 'dark' ? 'white__text' : ''}>{countPlan()}</h4>
            </div>

            <button type='button' className='btn btn-primary w-100 my-1' onClick={buyNowHandler}>
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingPlan
