import React from 'react'
import {Link, useLocation, useParams, useSearchParams} from 'react-router-dom'
import {useEmailVerification} from '../../hooks'
import {useFormik} from 'formik'
import {useState} from 'react'
import {isValidPhoneNumber} from 'libphonenumber-js'
import {register} from '../../modules/auth/core/_requests'
import {acceptInvitation} from '../../services/invitation'
import PhoneInput from 'react-phone-input-2'
import useTogglePassword from '../../hooks/useTogglePassword'
import clsx from 'clsx'
import * as Yup from 'yup'
import '../../styles/inputStyle.css'
import CustomAlert from '../CustomAlert'
import {useTheme} from '../../context/ThemeContext'


interface Roles {
  id: string
  name: string
  description: string
  isSuperAdmin: boolean
  isAdmin: boolean
  createdAt: string
  updatedAt: string
}

//eslint-disable-next-line
const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@~\$%\^\*])(?=.{12,})/
// const nameReg = /^[a-zA-Z ._-]*$/
const registrationIndividualSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, 'Maximum 40 characters are allowed.')
    .min(3, 'Minimum 3 characters are required.')
    .required('Name is required')
    .trim(),
    boardName: Yup.string()
    .required('Board Name is required')
    .max(40, 'Maximum 40 characters are allowed.')
    .min(3, 'Minimum 3 characters are required.')
    .trim(),
    pageTitle: Yup.string()
    .required('Page Name is required')
    .max(40, 'Maximum 40 characters are allowed.')
    .min(3, 'Minimum 3 characters are required.')
    .trim(),
  // .matches(nameReg, 'Name should be in Upper or Lower case letters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .trim()
    .matches(
      passwordReg,
      'Please use 12 or more characters with a mix of letters, numbers and any of the following special characters: !,@,~,$,%,^ or *'
    ),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const RegistrationFormIndividual = ({
  decodeTokenHandler,
  loginHandler,
  roleList,
  loading,
  setLoading,
  handleClose,
  handleShow,
}: any): React.JSX.Element => {
  const {theme} = useTheme()
  const [searchParams] = useSearchParams()

  const [popUpMsg, setPopUpMsg] = useState('')
  const [phoneState, setPhoneState] = useState('')
  const [errorMsgRegistration, setErrorMsgRegistration] = useState('')
  const {emailVerificationHandler} = useEmailVerification()
  const {passwordType, togglePassword} = useTogglePassword()
  const params: any = useParams()
  const location: any = useLocation()
  const decodedEmail = location?.state?.decodedDataEmail || false
  const currentTier = searchParams.get('tier') || 'Introductory'
  const initialValues = {
    name: '',
    email: decodedEmail || '',
    password: '',
    image: null,
    roleId: '',
    boardName: 'b4igo',
    pageTitle: 'My Fantastic Idea',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationIndividualSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      const role = roleList.find((roles: Roles) => roles.name === 'User')?.id
      values.roleId = role
      let validNumber = true
      if (phoneState.length > 0) {
        validNumber = isValidPhoneNumber('+' + phoneState)
      }
      if (validNumber) {
        const isInvitedByOrganization = false
        const board_name=values.boardName
        const pageTitle=values.pageTitle
        try {
          const {data: auth} = await register(
            values.boardName,
            values.pageTitle,
            values.email,
            values.name,
            values.password,
            phoneState.length > 0 ? phoneState : null,
            values.image,
            values.roleId,
            isInvitedByOrganization,
            currentTier,
          )
          emailVerificationHandler({
            userId: auth.user.id,
            fullName: values.name,
            email: values.email,
            chosenSubscriptionPlan: currentTier,
          })

          setTimeout(() => {
            handleClose()
            loginHandler(values.email, values.password)
          }, 5000)

          if (params.token) {
            try {
              const payload: any = await decodeTokenHandler(auth.user.id)
              await acceptInvitation(payload)
            } catch (error: any) {
              const msg = error.response.data.message
              setErrorMsgRegistration(msg)
              return error
            }
          }
          handleShow()

          setLoading(false)
        } catch (error: any) {
          const msg = error.response.data.message
          setErrorMsgRegistration(msg)
        } finally {
          setSubmitting(false)
          setLoading(false)
          formik.resetForm()
          formik.setValues({...formik.values,boardName:board_name})
          formik.setValues({...formik.values,pageTitle:pageTitle})
        }
      } else {
        setPopUpMsg('Please Enter Valid Phone Number!')
      }
      setSubmitting(false)
      setLoading(false)
    },
  })

  return (
    <form
      className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
        theme === 'dark' ? 'theme__div' : ''
      }`}
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className={`row fv-row mt-5  ${theme === 'dark' ? 'theme__div' : ''}`}>
        <div className='col-xl-12 my-3'>
          <label
            className={`class="form-label fw-bold ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            } fs-6`}
          >
            Please enter your name
          </label>
          <input
            placeholder='Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              `form-control form-control-lg form-control-solid  ${
                theme === 'dark' ? 'dark__theme__input' : ''
              }  italics-placeholder`,
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.name}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-12 my-3'>
          <label
            className={`class="form-label fw-bold ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            } fs-6 mb-0`}
          >
            Enter your email address
          </label>
          <input
            placeholder='Email Address'
            disabled={decodedEmail ? true : false}
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              `form-control form-control-lg form-control-solid  ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } italics-placeholder`,
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.email as any}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='col-xl-12 my-3' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label
              className={`class="form-label fw-bold ${
                theme === 'dark' ? 'white__text' : 'text-dark'
              } fs-6 mb-0`}
            >
              Phone (optional)
            </label>
            <div className='position-relative mb-3'>
              <PhoneInput
                placeholder='+44 7700 900077'
                country='44'
                value={phoneState}
                onChange={(phone) => setPhoneState(phone)}
                inputClass={`w-100 ${theme === 'dark' ? 'white__text' : ''}`}
                          dropdownClass={theme === 'dark' ? 'theme__div' : ''}
                          containerClass={theme === 'dark' ? 'react-tel-input-dark' : 'react-tel-input-light'}
              />
            </div>
          </div>
        </div>

        <div className='col-xl-12 my-3'>
          <label
            className={`class="form-label fw-bold ${
              theme === 'dark' ? 'white__text' : 'text-dark'
            } fs-6 mb-0`}
          >
            Create a password
          </label>
          <div className='position-relative mb-3'>
            <div className='input-group'>
              <input
                type={passwordType}
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  } italics-placeholder`,

                  {
                    'is-valid':
                      phoneState.length > 0 && formik.touched.password && !formik.errors.password,
                  }
                )}
              />

              <div className='input-group-btn'>
                <button
                  className={`${
                    theme === 'dark' ? 'theme__div' : ''
                  } btn border-end border-top border-bottom`}
                  style={{borderRadius: '0px 10px 10px 0px'}}
                  type='button'
                  onClick={togglePassword}
                >
                  {passwordType === 'password' ? (
                    <i
                      className={`  bi bi-eye-slash  ${theme === 'dark' ? 'white__text' : ''}`}
                    ></i>
                  ) : (
                    <i className={`bi bi-eye ${theme === 'dark' ? 'white__text' : ''}`}></i>
                  )}
                </button>
              </div>
            </div>

            {
              // phoneState.length > 0 &&
              formik.touched.password && formik.errors.password ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.password}
                    </span>
                  </div>
                </div>
              ) : (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='' style={{color: 'gray'}}>
                      Please use 12 or more characters with a mix of letters, numbers and any of the
                      following special characters: !,@,~,$,%,^ or *
                    </span>
                  </div>
                </div>
              )
            }
          </div>
        </div>
            <div className='col-xl-12 my-3'>
            <label
              className={`class="form-label fw-bold ${
                theme === 'dark' ? 'white__text' : 'text-dark'
              } fs-6`}
            >
              Name your first board
            </label>
          <input
            placeholder='Enter Board Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('boardName')}
            className={clsx(
              `form-control form-control-lg form-control-solid  ${
                theme === 'dark' ? 'dark__theme__input' : ''
              }  italics-placeholder`,
              {
                'is-invalid': formik.touched.boardName && formik.errors.boardName,
              },
              {
                'is-valid': formik.touched.boardName && !formik.errors.boardName,
              }
            )}
          />
          {formik.touched.boardName && formik.errors.boardName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.boardName}
                </span>
              </div>
            </div>
          )}
            </div>
            <div className='col-xl-12 my-3'>
            <label
              className={`class="form-label fw-bold ${
                theme === 'dark' ? 'white__text' : 'text-dark'
              } fs-6`}
            >
              Name your first page
            </label>
          <input
            placeholder='Enter Page Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('pageTitle')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              }  italics-placeholder`,
              {
                'is-invalid': formik.touched.pageTitle && formik.errors.pageTitle,
              },
              {
                'is-valid': formik.touched.pageTitle && !formik.errors.pageTitle,
              }
            )}
          />
          {formik.touched.pageTitle && formik.errors.pageTitle && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {formik.errors.pageTitle}
                </span>
              </div>
            </div>
          )}
           <div className='fv-plugins-message-container py-2'>
            <div className='fv-help-block'>
              <span role='alert' className='font-italic' style={{color: 'gray'}}>
              To help you get started quickly, we&lsquo;ve created your first board upon sign-up.
              You can keep the default name &quot;b4igo&quot; or change it to something that reflects
              your current idea or project. If you&lsquo;re unsure of what to name your first board,
              you can always rename it later.
              <br/>
              <br/>
              Additionally, we&lsquo;ve set up the first two columns in the tasks view as a template
              for column names and colors. Feel free to customize these column names to
              better fit your style and project needs.
              <br/>
              <br/>
              This board will be set as your default. While Adding notes or tasks from the Home Screen, it will automatically appear in Board field. You can switch to a different board while creating the note or task, or move it later if needed.
              <br />
              To change your default board, go to your User Profile Settings via the Profile icon in the top right corner after logging in.
              </span>

            </div>
        </div>
            </div>
      </div>

      <div className='my-3'>
        <CustomAlert
          variant={'info'}
          alertMessage={popUpMsg}
          setAlertMessage={setPopUpMsg}
          time={5000}
        />
      </div>

      <div className='my-3'>
        <CustomAlert
          variant={'danger'}
          alertMessage={errorMsgRegistration}
          setAlertMessage={setErrorMsgRegistration}
          time={5000}
        />
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && <span className='indicator-label'>Loading...</span>}
        </button>
        <Link to='/auth'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'

            className='btn btn-lg btn-secondary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      <p className='text-muted'>
        You can turn your account into an organisation account later in the Manage Account section,
        once you have logged in.
      </p>
    </form>
  )
}

export default RegistrationFormIndividual
