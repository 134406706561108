/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {useEffect, useState} from 'react'
import {sendPushAlert} from '../services/notification'
import {createRetainAlertInfo, createRetainAlertInfoForPage, getRetainAlertInfo, getRetainAlertInfoForPage} from '../services/alerts'
import {capitalizeEachWord} from '../helper-functions/CapitalizeName'
import {useAuth} from '../modules/auth'

const useSendAlert = (
  // boardTitle: any,
  boardPersonsForSendAlert: any,
  cardDetails: any,
  boardId: string,
  editorComment: any,
  editorDescriptionState: string | null
) => {
  const [sendAlertTo, setSendAlertTo] = useState<any>([])
  const [sendAlertToComment, setSendAlertToComment] = useState<any>([])
  const [sendAlertMsg, setSendAlertMsg] = useState('')
  const [sendAlertMsgComment, setSendAlertMsgComment] = useState('')

  const {currentUser}: any = useAuth()
  const {fullName, email} = currentUser?.data.user
  const sendAlertToBoardPerson = async (cardsData:any,sendTo: any, alertType: string) => {
    const selectedPersonsForAlert = []
    const retainAlertInfo = []
    let retainAlert: any
    const retainAlertNotes:any= {pageId:cardsData?.id,
      userIds:[]
    }

    for (let i = 0; i < boardPersonsForSendAlert.length; i++) {
      for (let j = 0; j < sendTo.length; j++) {
        if (boardPersonsForSendAlert[i].id === sendTo[j].value) {
          selectedPersonsForAlert.push(boardPersonsForSendAlert[i])
          let data: any
          if (alertType === 'card-description') {
            const rawText = editorDescriptionState?.replace(/<[^>]+>/g, '')
            const slicedText = rawText?.slice(0, 77) + '...'
            const textToNotify = rawText && rawText?.length > 77 ? slicedText : rawText
            data = {
              eventName: 'card-description',
              subscriberEmail: boardPersonsForSendAlert[i].email,
              subscriberId: boardPersonsForSendAlert[i].id,
              payload: {
                boardId: cardDetails?.boardId || cardsData?.boardId,
                cardNumber: cardDetails?.number||cardsData?.number,
                cardCreatorName: capitalizeEachWord(fullName),
                boardname: cardDetails?.board_name||cardsData?.board_name,
                description: textToNotify,
                cardname: cardDetails?.card_name||cardsData?.card_name,
                cardKey: cardDetails?.key||cardsData?.key,
              },
            }
            retainAlertInfo.push({
              cardId: cardDetails?.id||cardsData.id,
              type: 'description',
              userId: boardPersonsForSendAlert[i].id,
              email: boardPersonsForSendAlert[i].email,
              card_commentId: null,
            })
          } else if (alertType === 'card-comment') {
            const rawText = editorComment?.replace(/<[^>]+>/g, '')
            const slicedText = rawText.slice(0, 77) + '...'
            const textToNotify = rawText.length > 77 ? slicedText : rawText
            data = {
              eventName: 'card-comment',
              subscriberEmail: boardPersonsForSendAlert[i].email,
              subscriberId: boardPersonsForSendAlert[i].id,
              payload: {
                boardId: cardDetails.boardId,
                cardNumber: cardDetails.number,
                commenter: capitalizeEachWord(fullName),
                boardname: cardDetails.board_name,
                rawtext: textToNotify,
                cardname: cardDetails.card_name,
              },
            }
          }
          else if (alertType === 'page-description') {
            const rawText:any = editorDescriptionState?.replace(/<[^>]+>/g, '')
            const slicedText = rawText.slice(0, 77) + '...'
            const textToNotify = rawText.length > 77 ? slicedText : rawText
            data = {
              eventName: 'page-description',
              subscriberEmail: boardPersonsForSendAlert[i].email,
              subscriberId: boardPersonsForSendAlert[i].id,
              payload: {
                boardId:cardsData?.boardId,
                pageCreatorName:capitalizeEachWord(fullName),
                // boardName:'dummy boardTitle',
                description: textToNotify,
                pageTitle:cardsData?.title,
              },
            }
            retainAlertNotes?.userIds.push(
              boardPersonsForSendAlert[i].id,
            )
          }

          try {
            await sendPushAlert(data)
            if (alertType === 'card-description') {
              retainAlert = {info: retainAlertInfo}
              setSendAlertMsg('Alert Sent!')
              try {
                await createRetainAlertInfo(retainAlert)
              } catch (error) {
            // console.log(error)
              }
            } else if (alertType === 'card-comment') {
              // retainAlert = { info: retainAlertInfoComment }
              setSendAlertMsgComment('Alert Sent!')
            }else if (alertType === 'page-description') {
              // retainAlert = {info: retainAlertInfo}
              setSendAlertMsg('Alert Sent!')
              try {
                await createRetainAlertInfoForPage(retainAlertNotes)
              } catch (error) {
            // console.log(error)
              }
            }
          } catch (error) {
            // console.log(error)
          } finally {
            setTimeout(() => {
              setSendAlertMsgComment('')
            }, 5000)
          }
        }
      }
    }
  }

  const getSendAlertedRetainInfoHandler = async () => {
    try {
        if(cardDetails?.website_name){
        const res = await getRetainAlertInfoForPage(cardDetails.id)
      const selectedPersonsSendSAlert = []

      const persons: any = res.data.data.alerts
      for (let index = 0; index < persons.length; index++) {
        if (persons[index].email !== email) {
          const checkUserIsDeleted = boardPersonsForSendAlert.find(
            (item: any) => item.email === persons[index].email
          )?.isDeleted
          const objData = {
            label:
              capitalizeEachWord(persons[index].user.fullName),
            value: persons[index].userId,
            isDisabled: checkUserIsDeleted,
          }
          selectedPersonsSendSAlert.push(objData)
        }
      }

      setSendAlertTo(selectedPersonsSendSAlert)
      }else{
      const res = await getRetainAlertInfo(cardDetails.id)
      const selectedPersonsSendSAlert = []

      const persons: any = res.data.data.alerts
      for (let index = 0; index < persons.length; index++) {
        if (persons[index].email !== email) {
          const checkUserIsDeleted = boardPersonsForSendAlert.find(
            (item: any) => item.email === persons[index].email
          )?.isDeleted
          const objData = {
            label:
              capitalizeEachWord(persons[index].user.fullName),
            value: persons[index].userId,
            isDisabled: checkUserIsDeleted,
          }
          selectedPersonsSendSAlert.push(objData)
        }
      }

      setSendAlertTo(selectedPersonsSendSAlert)
    }
    } catch (error) {
            // console.log(error)
    }
  }

  useEffect(() => {
    getSendAlertedRetainInfoHandler()
    //eslint-disable-next-line
  }, [boardId, boardPersonsForSendAlert])

  return {
    sendAlertTo,
    setSendAlertTo,
    sendAlertToComment,
    setSendAlertToComment,
    sendAlertMsg,
    setSendAlertMsg,
    sendAlertMsgComment,
    setSendAlertMsgComment,
    sendAlertToBoardPerson,
  }
}

export default useSendAlert
