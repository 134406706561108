import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from 'react'
import ReactQuill from 'react-quill'
import {capitalizeEachWord, capitalizeEachWordInitials} from '../../helper-functions/CapitalizeName'
import {db} from '../../../index'
import EmojiPicker, {Theme} from 'emoji-picker-react'
import {debounce} from 'lodash'

import ReactToolTip from '../ReactToolTip'

import {
  // serverTimestamp,
  arrayUnion,
  doc,
  updateDoc,
  deleteDoc,
  arrayRemove,
} from 'firebase/firestore'
import {useAuth} from '../../modules/auth'

import ReactS3Client from 'react-aws-s3-typescript'
import CommentsAttachmentTable from './CommentsAttachmentTable'
import Reaction from './Reaction'
import SendCommentAlert from '../Select/SendCommentAlert'
import {awsCommentFileUploadConfig} from '../../config/awsCommentFileUpload'
import {TextEditor} from '../TextEditor'
import {useEditModal} from '../../context/ShowEditModalQuill'
import {useSelector} from 'react-redux'
import '../../styles/richTextStyle.css'
import {checkStringSize} from '../../helper-functions/checkStringSize'
import CustomAlert from '../CustomAlert'
import {updateComments, deleteComments} from '../../services/comments'
import {useTheme} from '../../context/ThemeContext'

const SingleComments = forwardRef(
  (
    {
      sendAlertToBoardPerson,
      comment,
      cardDetails,
      handleFormSubmission,
      // index,
      // sendComment,
      // setImageSrc,
      optionsForSendAlert,
      setSendAlertToComment,
      sendAlertToComment,
    }: any,
    parentRef: any
  ) => {
    const quillRef = useRef<ReactQuill>(null)
    const {setShowEditModalQuill, setCard} = useEditModal()
    const {allCurrentUserCards} = useSelector((state: any) => state.cardReducer)
    const {theme} = useTheme()
    const {boardId, id: cardId} = cardDetails
    const [isEditable, setIsEditable] = useState(false)

    const [showEmojiOverlay, setShowEmojiOverlay] = useState(false)

    const [editorDescription, setEditorDescription] = useState(comment.text)
    const [cardSelected, setCardSelected] = useState<any>(null)
    const [fileErrorMsgComment, setFileErrorMsgComment] = useState('')
    // const [selectedFile, setSelectedFile] = useState<any>([])
    // const [uploadedFileURL, setUploadedFileURL] = useState<any>([])
    const s3 = new ReactS3Client({
      ...awsCommentFileUploadConfig,
      dirName: `attachments/${process.env.REACT_APP_ENVIRONMENT}`,
    })
    const {currentUser} = useAuth()
    const {id, fullName} = currentUser?.data.user
    const ref: any = useRef(null)

    const UpdateSingleCommentHandler = async (data: any) => {
      if (sendAlertToComment.length > 0) {
        sendAlertToBoardPerson(undefined, sendAlertToComment, 'card-comment', null)
        data.alertSentTo = sendAlertToComment
      }

      if (checkStringSize(data?.text) > 1048487) {
        setFileErrorMsgComment(
          'The media size exceeds 1MB. please consider file uploader section for large media.'
        )
        return
      } else {
        const docRef = doc(
          db,
          `comments-module/${process.env.REACT_APP_FIREBASE_ENVIRONMENT}/boards/${boardId}/cards/${cardId}/comments`,
          `${comment.id}`
        )
        await updateDoc(docRef, data)
        if (data.text) {
          updateCommentsInDatabase('', '', data?.text)
          setIsEditable(!isEditable)
        }
      }
    }

    const handleMouseover = useCallback(
      debounce((id: any) => {
        const cardInfo: any = allCurrentUserCards?.find((card: any) => `${card?.key.trim()}` === id)
        setCard({})
        setCardSelected(null)

        if (cardInfo) {
          setCardSelected(cardInfo)
        }
      }, 100),
      []
    )
    const debouncedOnChange = debounce(handleMouseover, 300)

    const DeleteSingleCommentHandler = async () => {
      await deleteDoc(
        doc(
          db,
          `comments-module/${process.env.REACT_APP_FIREBASE_ENVIRONMENT}/boards/${boardId}/cards/${cardId}/comments`,
          `${comment.id}`
        )
      )
      if (comment.attachments) {
        const images = comment.attachments
        for (let index = 0; index < images.length; index++) {
          const fileNameForDelete = images[index].slice(52)
          await s3.deleteFile(fileNameForDelete)
        }
      }

      await deleteComments(comment.id, id, boardId)
    }
    const uploadSelectFileHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const files = Array.from(event.target.files);
    
        const validFiles = files.filter(
          (file: any) =>
            file.size <= 25000000 &&
            !file.name.toLowerCase().endsWith('.html') &&
            !file.name.toLowerCase().endsWith('.mhtml')
        );
        
        if(validFiles.length>0){
          try {
             await Promise.all(validFiles.map(async file => {
              const filename = file.name.substring(0, file.name.lastIndexOf('.'));
              const uploadFile = await s3.uploadFile(file, filename);
              await UpdateSingleCommentHandler({ attachments: arrayUnion(uploadFile.location) });
              await updateCommentsInDatabase(uploadFile.location, 'update');
              return uploadFile.location;
            }));
      
            // setUploadedFileURL((prev:any) => [...prev, ...uploadedURLs]);
            // setSelectedFile((prev:any) => [...prev, ...validFiles]);
          } catch (error) {
            // console.error('Error uploading files:', error);
          }
        }
      }
    };
    

    const updateCommentsInDatabase = async (
      fileNames: string,
      actionName: string,
      text = comment.text
    ) => {
      let urls: string[] = []

      if (actionName === 'delete') {
        // Remove file names from the existing attachments
        urls = comment?.attachments?.filter((url: string) => !fileNames.includes(url)) || [];
      } else if (actionName === 'update') {
        // Add file names to the existing attachments
        urls = [...new Set([...(comment?.attachments || []), ...fileNames])];
      }

      if (fileNames.length === 0 && (comment?.attachments?.length ?? 0) > 0) {
        urls = [...comment?.attachments];
      }

      const data = {
        rawtext: text,
        files: urls,
      };

      await updateComments(comment?.id, data)
    }

    const deleteFileHandler = async (fileName: string) => {
      try {
        await s3.deleteFile(fileName.slice(52))
        updateCommentsInDatabase(fileName, 'delete')
        UpdateSingleCommentHandler({attachments: arrayRemove(fileName)})
      } catch (error) {
        return error
      }
    }

    const addEmojiHandler = (e: any) => {
      // setEmoji(e.emoji)
      UpdateSingleCommentHandler({
        reaction: arrayUnion({reactedBy: fullName, emoji: e.emoji}),
      })
      setShowEmojiOverlay(false)
    }

    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowEmojiOverlay(false)
      }
    }

    const closeEditorHandler = () => {
      setIsEditable(!isEditable)
      setEditorDescription(comment.text)
    }

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }, [])

    function hasOnlySpaces(str: string) {
      const regex = /<p class="spacer">\s*<\/p>/
      return !regex.test(str)
    }

    useImperativeHandle(parentRef, () => ({
      updateChildSingleComment() {
        if (
          editorDescription.length > 0 &&
          !/<p class="spacer"><br><\/p>/.test(editorDescription) &&
          editorDescription !== comment.text &&
          hasOnlySpaces(editorDescription)
        ) {
          UpdateSingleCommentHandler({text: editorDescription})
        }
      },
    }))

    function CardData({data}: {data: string}): React.ReactElement {
      return <span dangerouslySetInnerHTML={{__html: data}} />
    }

    useEffect(() => {
      const editor: any = quillRef?.current?.editor as any
      const handleClick = (e: any) => {
        if (e.target.parentElement.className === 'mention') {
          e.preventDefault()
          if (handleFormSubmission) {
            handleFormSubmission()
          }
          setCard(
            allCurrentUserCards?.find(
              (card: any) =>
                `${card?.key.trim()}` === e.target.textContent?.trim()?.replace('/', '')
            )
          )

          setShowEditModalQuill(true)
          setCardSelected(null)
        }
        if (e.target.tagName === 'A') {
          e.preventDefault()
          if (/\/\S*-\d+\b/.test(e.target.innerHTML)) {
            if (handleFormSubmission) {
              handleFormSubmission()
            }

            setCard(
              allCurrentUserCards?.find(
                (card: any) => `${card?.key.trim()}` === e.target.innerText.replace('/', '').trim()
              )
            )
            setShowEditModalQuill(true)
            setCardSelected(null)
          }
        }
      }
      const handleOverEvent = (e: any) => {
        if (e.target.parentElement.className === 'mention') {
          const removeSlash = e.target.innerHTML.replace('/', '')
          e.target.setAttribute(
            'data-tooltip-id',
            `my-tooltip-children-multiline-read-${removeSlash.trim()}`
          )
          e.target.setAttribute(
            'style',
            'max-width: 30%; cursor: pointer; text-decoration: underline; display: inline;'
          )
          editor?.container.addEventListener('click', handleClick)
          editor?.container.addEventListener('mouseover', handleOverEvent)
          debouncedOnChange(removeSlash)
        }
        if (e.target.tagName === 'A') {
          if (/\/\S*-\d+\b/.test(e.target.innerHTML)) {
            const removeSlash = e.target.innerHTML.replace('/', '')
            e.target.setAttribute(
              'data-tooltip-id',
              `my-tooltip-children-multiline-read-${removeSlash.trim()}`
            )
            e.target.classList.add('pattern-class')
            e.target.setAttribute(
              'style',
              'max-width: 30%; cursor: pointer; text-decoration: underline; display: inline;'
            )
            debouncedOnChange(removeSlash)
          }

          editor?.container.addEventListener('click', handleClick)
          editor?.container.addEventListener('mouseover', handleOverEvent)
        }
      }

      return () => {
        editor?.container.removeEventListener('click', handleClick)
        editor?.container.removeEventListener('mouseover', handleOverEvent)
      }
    }, [comment.text, isEditable])
    return (
      <div className='row my-10' ref={parentRef}>
        <div className='col-1'>
          <span className='circle-cards'>
            <span className='circle-inner'>{capitalizeEachWordInitials(comment.name)}</span>
          </span>
        </div>
        <div className='col-11' style={{position: 'relative'}}>
          <p className='mb-1'>
            <span className='fw-bold fs-3'>{capitalizeEachWord(comment.name)}</span>{' '}
            <span className='fs-7 text-muted'>{comment.dateAndTime}</span>
          </p>

          {comment.alertSentTo && (
            <p className='fs-5'>
              Alert sent to:{' '}
              {comment.alertSentTo.map((item: any, index: number) => (
                <span key={index} className='bg-info text-white mx-1 px-1'>
                  {capitalizeEachWord(item.label)}
                </span>
              ))}
            </p>
          )}

          <div
            className={`${
              isEditable ? '' : theme === 'dark' ? 'rounded' : 'bg-light rounded'
            } ps-2`}
          >
            {!isEditable && comment?.text && (
              <ShowComments comments={comment.text} quillRef={quillRef} />
            )}
            {isEditable && (
              <>
                <TextEditor
                  setEditor={setEditorDescription}
                  editor={editorDescription}
                  placeholder={'Update Comment'}
                  forTitle='comments'
                  handleFormSubmission={handleFormSubmission}
                />
              </>
            )}
            {comment.attachments?.length > 0 && (
              <div className='card-body p-0 px-1'>
                <CommentsAttachmentTable
                  deleteFileHandler={deleteFileHandler}
                  attachments={comment.attachments || []}
                  isEditable={isEditable}
                />
              </div>
            )}
          </div>

          {!isEditable && (
            <>
              <Reaction
                reactions={comment.reaction}
                setShowEmojiOverlay={setShowEmojiOverlay}
                showEmojiOverlay={showEmojiOverlay}
              />
              {showEmojiOverlay && (
                <div style={{position: 'absolute', zIndex: '1000'}} ref={ref}>
                  <EmojiPicker
                    theme={theme === 'dark' ? ('dark' as Theme) : ('light' as Theme)}
                    onEmojiClick={(e: any) => addEmojiHandler(e)}
                    lazyLoadEmojis={true}
                  />
                </div>
              )}
            </>
          )}

          {isEditable && (
            <div className='ms-2'>
             <h6 className={`my-3 ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>Send Alert To:</h6>
              <SendCommentAlert
                options={optionsForSendAlert}
                setSelected={setSendAlertToComment}
                selected={sendAlertToComment}
              />
            </div>
          )}

          {!isEditable && comment.userId === id && (
            <div className='d-inline-block'>
              <span
                className='text-muted fs-4 ms-3 pt-2'
                style={{cursor: 'pointer'}}
                onClick={() => setIsEditable(!isEditable)}
              >
                Edit
              </span>

              <span
                className='text-muted fs-4 ms-3 pt-2'
                style={{cursor: 'pointer'}}
                onClick={DeleteSingleCommentHandler}
              >
                Delete
              </span>
            </div>
          )}

          {isEditable && (
            <div className='d-inline-block'>
              <span
                className='text-muted fs-4 ms-3 pt-2'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  const cleanedContent = editorDescription.replace(/<p>\s*<br>\s*<\/p>/g, '').trim();
                  if (cleanedContent.length > 0 ) {
                    UpdateSingleCommentHandler({ text: editorDescription });
                  } else{
                    setFileErrorMsgComment('Comment cannot be empty')
                  }
                }}
              >
                Save
              </span>

              <label className='text-muted fs-4 ms-3 pt-2' style={{cursor: 'pointer'}}>
                Add Attachment
                <input
                  hidden
                  multiple
                  type='file'
                  className='text-muted fs-4 ms-3 pt-2'
                  style={{cursor: 'pointer'}}
                  onChange={uploadSelectFileHandler}
                />
              </label>

              <span
                className='text-muted fs-4 ms-3 pt-2'
                style={{cursor: 'pointer'}}
                onClick={closeEditorHandler}
              >
                Close
              </span>
            </div>
          )}
          <CustomAlert
            variant={'warning'}
            className={'mt-2 w-100'}
            alertMessage={fileErrorMsgComment}
            setAlertMessage={setFileErrorMsgComment}
          />
          {cardSelected && (
            <ReactToolTip id={`my-tooltip-children-multiline-read-${cardSelected.key.trim()}`}>
              <div id='react-tooltip-custom'>
                <div className='custom-card'>
                  <h5 style={{margin: 0, textDecoration: 'underline'}}>{cardSelected.card_name}</h5>
                  <span className='custom-card-description'>
                    {cardSelected?.description ? (
                      <CardData data={cardSelected?.description} />
                    ) : (
                      <span>No Description</span>
                    )}
                  </span>
                </div>
              </div>
            </ReactToolTip>
          )}
        </div>
      </div>
    )
  }
)
SingleComments.displayName = 'SingleComments'

export default SingleComments

const ShowComments = React.memo(({comments, quillRef}: {comments: string; quillRef: any}) => {
  const {theme} = useTheme()
  return (
    <div className={`${theme === 'dark' ? 'dark-mode-placeholder' : ''}`}>
      <ReactQuill
        className={`${'image-resize'} ${
          theme === 'dark' ? 'dark__theme__input border border-1 rounded border-secondary' : ''
        }`}
        value={comments}
        readOnly={true}
        theme={'bubble'}
        ref={quillRef}
      />
    </div>
  )
})
ShowComments.displayName = 'ShowComments'
