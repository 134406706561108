import React, {useState} from 'react'
import Form from 'react-bootstrap/Form'
import Skeleton from 'react-loading-skeleton'
import {useAuth} from '../../../auth'
import {useTheme} from '../../../../context/ThemeContext'
// import { useSelector } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AccountContacts = ({
  setSelectedRows,
  selectedRows,
  membersData,
  flag,
  otherContactList,
  loading,
  onSelectedRowsChange,
}: any): React.JSX.Element => {
  // const { currentAccount } = useSelector((state: any) => state.SwitchAccountReducer)

  const {theme} = useTheme()
  const [selectedRadioTable1, setSelectedRadioTable1] = useState<any>({})
  const [selectedRadioTable2, setSelectedRadioTable2] = useState<any>({})
  const {currentUser} = useAuth()
  const {hasOrganization} = currentUser

  const handleRadioChange = (rowId: any, value: any, isTable1: boolean) => {
    if (isTable1) {
      setSelectedRadioTable1((prevSelectedRadio: any) => ({
        ...prevSelectedRadio,
        [rowId]: value,
      }))
    } else {
      setSelectedRadioTable2((prevSelectedRadio: any) => ({
        ...prevSelectedRadio,
        [rowId]: value,
      }))
    }
  }

  const handleCrossButtonClick = (rowId: any, isTable1: boolean) => {
    if (isTable1) {
      setSelectedRadioTable1((prevSelectedRadio: any) => {
        const updatedRadio = {...prevSelectedRadio}
        delete updatedRadio[rowId]
        return updatedRadio
      })
      const updatedRows = selectedRows.filter((item: any) => item.id !== rowId)
      setSelectedRows(updatedRows)
    } else {
      setSelectedRadioTable2((prevSelectedRadio: any) => {
        const updatedRadio = {...prevSelectedRadio}
        delete updatedRadio[rowId]
        return updatedRadio
      })
      const updatedRows = selectedRows.filter((item: any) => item.id !== rowId)
      setSelectedRows(updatedRows)
    }
  }
  const isEmailInOtherConcats = (email: string) =>
    membersData.some((member: any) => (member.email === email ? true : false))

  return (
    <div>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer '
        >
          {flag && (
            <thead className='border-bottom'>
              <th
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 min-w-100px'
                style={{maxWidth: '180px', width: '180px'}}
              >
                Name
              </th>
              {/* <th className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              Designation
            </th> */}
              <th
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 min-w-100px'
                style={{maxWidth: '180px', width: '180px'}}
              >
                Company/Organisation
              </th>
              <th
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 min-w-100px'
                style={{maxWidth: '180px', width: '180px'}}
              >
                Email
              </th>
              <th
                className='text-center text-muted fw-bolder fs-7 text-uppercase gs-0 min-w-100px'
                style={{maxWidth: '40%', width: '40%'}}
              >
                Role
              </th>
            </thead>
          )}
          {loading && (
            <tbody>
              <tr className='my-2'>
                <td>
                  <Skeleton count={1} height={25} />
                </td>
                {/* <td>
                  <Skeleton
                    count={1}
                    height={25}
                  />
                </td> */}
                <td>
                  <Skeleton count={1} height={25} />
                </td>
                <td>
                  <Skeleton count={1} height={25} />
                </td>
                <td>
                  <Skeleton count={1} height={25} />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && (
            <tbody className='text-gray-600 fw-bold border-bottom'>
              {otherContactList?.length > 0 &&
                !flag &&
                otherContactList?.map((member: any) => (
                  <tr
                    title={isEmailInOtherConcats(member.email) && 'This user is already invited'}
                    className='text-start text-muted fw-bolder fs-7 border-bottom gs-0'
                    key={member.id}
                    style={{cursor: isEmailInOtherConcats(member.email) ? 'not-allowed' : ''}}
                  >
                    <td
                      className={` min-w-100px ${theme === 'dark' ? 'white__text' : 'text-dark'}`}
                      style={{maxWidth: '180px', width: '180px'}}
                    >
                      <p style={{wordWrap: 'break-word', maxWidth: '130px'}}>{member.fullName}</p>
                    </td>

                    <td
                      className={` min-w-100px ${theme === 'dark' ? 'white__text' : 'text-dark'}`}
                      style={{maxWidth: '180px', width: '180px'}}
                    >
                      {member?.companyName ? (
                        <p style={{wordWrap: 'break-word', maxWidth: '130px'}}>
                          {member?.companyName}
                        </p>
                      ) : (
                        <p style={{fontSize: '24', textAlign: 'center', marginRight: '30px'}}>-</p>
                      )}
                    </td>
                    <td
                      className={` min-w-100px ${theme === 'dark' ? 'white__text' : 'text-dark'}`}
                      style={{maxWidth: '180px', width: '180px'}}
                    >
                      <p style={{wordWrap: 'break-word', maxWidth: '130px', marginLeft: '35px'}}>
                        {member.email}
                      </p>
                    </td>
                    <td className=' text-dark min-w-100px' style={{maxWidth: '40%', width: '40%'}}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                          gap: '18px',
                        }}
                      >
                        <Form.Check
                          className={theme === 'dark' ? 'white__text' : ''}
                          type='radio'
                          id={`custom-radio-${member.id}`}
                          label='Admin'
                          value='admin'
                          checked={selectedRadioTable2[member.id] === 'Admin'}
                          onChange={() => {
                            handleRadioChange(member.id, 'Admin', false)
                            onSelectedRowsChange(member.id, member.email, 'Admin')
                          }}
                          disabled={isEmailInOtherConcats(member.email)}
                        />
                        <Form.Check
                          className={theme === 'dark' ? 'white__text' : ''}
                          type='radio'
                          id={`custom-radio-2-${member.id}`}
                          label='Member'
                          value='member'
                          checked={selectedRadioTable2[member.id] === 'Member'}
                          onChange={() => {
                            handleRadioChange(member.id, 'Member', false)
                            onSelectedRowsChange(member.id, member.email, 'Member')
                          }}
                          disabled={isEmailInOtherConcats(member.email)}
                        />
                        <Form.Check
                          className={theme === 'dark' ? 'white__text' : ''}
                          type='radio'
                          id={`custom-radio-3-${member.id}`}
                          label='Guest'
                          value='guest'
                          checked={selectedRadioTable2[member.id] === 'Guest'}
                          onChange={() => {
                            handleRadioChange(member.id, 'Guest', false)
                            onSelectedRowsChange(member.id, member.email, 'Guest')
                          }}
                          disabled={isEmailInOtherConcats(member.email)}
                        />
                      </div>
                    </td>
                    <td>
                      <i
                        style={{fontSize: '25px'}}
                        className={`${
                          theme === 'dark' ? 'white__text' : ''
                        } bi bi-x cursor-pointer`}
                        onClick={() => handleCrossButtonClick(member.id, false)}
                      ></i>
                    </td>
                  </tr>
                ))}
              {otherContactList.length > 0 &&
                flag &&
                otherContactList?.map((member: any) => (
                  <tr
                    title={isEmailInOtherConcats(member.email) && 'This user is already invited'}
                    className='text-start text-muted fw-bolder fs-7 border-bottom gs-0 p-2'
                    key={member.id}
                    style={{cursor: isEmailInOtherConcats(member.email) ? 'not-allowed' : ''}}
                  >
                    <td
                      className={`${theme === 'dark' ? 'white__text' : 'text-dark'}  min-w-100px`}
                      style={{maxWidth: '180px', width: '180px'}}
                    >
                      <p style={{wordWrap: 'break-word', maxWidth: '130px'}}>
                        {member?.user?.fullName || member.fullName}
                      </p>
                    </td>

                    <td
                      className={` ${theme === 'dark' ? 'white__text' : 'text-dark'} min-w-100px `}
                      style={{maxWidth: '180px', width: '180px'}}
                    >
                      {member?.companyName ? (
                        <p style={{wordWrap: 'break-word', maxWidth: '130px'}}>
                          {member?.companyName}
                        </p>
                      ) : (
                        <p style={{fontSize: '24', textAlign: 'center', marginRight: '30px'}}>-</p>
                      )}
                    </td>
                    <td
                      className={` ${theme === 'dark' ? 'white__text' : 'text-dark'}  min-w-100px`}
                      style={{maxWidth: '180px', width: '180px'}}
                    >
                      <p style={{wordWrap: 'break-word', maxWidth: '130px', marginLeft: '35px'}}>
                        {member.email}
                      </p>
                    </td>
                    <td
                      className={` ${theme === 'dark' ? 'white__text' : 'text-dark'}  min-w-100px`}
                      style={{maxWidth: '40%', width: '40%'}}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                          gap: '18px',
                        }}
                      >
                        <Form.Check
                          className={theme === 'dark' ? 'white__text' : ''}
                          type='radio'
                          id={`custom-radio-${member.id}`}
                          label='Admin'
                          value='admin'
                          checked={selectedRadioTable1[member.id] === 'Admin'}
                          onChange={() => {
                            handleRadioChange(member.id, 'Admin', true)
                            onSelectedRowsChange(member.id, member.email, 'Admin')
                          }}
                          disabled={isEmailInOtherConcats(member.email)}
                        />
                        <Form.Check
                          className={theme === 'dark' ? 'white__text' : ''}
                          type='radio'
                          id={`custom-radio-2-${member.id}`}
                          label='Member'
                          value='member'
                          checked={selectedRadioTable1[member.id] === 'Member'}
                          onChange={() => {
                            handleRadioChange(member.id, 'Member', true)
                            onSelectedRowsChange(member.id, member.email, 'Member')
                          }}
                          disabled={isEmailInOtherConcats(member.email)}
                        />
                        {!hasOrganization && (
                          <Form.Check
                            className={theme === 'dark' ? 'white__text' : ''}
                            type='radio'
                            id={`custom-radio-3-${member.id}`}
                            label='Guest'
                            value='guest'
                            disabled={isEmailInOtherConcats(member.email)}
                            checked={selectedRadioTable1[member.id] === 'Guest'}
                            onChange={() => {
                              handleRadioChange(member.id, 'Guest', true)
                              onSelectedRowsChange(member.id, member.email, 'Guest')
                            }}
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      <i
                        style={{fontSize: '25px'}}
                        className={`${
                          theme === 'dark' ? 'white__text' : ''
                        } bi bi-x cursor-pointer`}
                        onClick={() => handleCrossButtonClick(member.id, true)}
                      ></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        {otherContactList.length === 0 && flag && (
          <h6 className={`text-center mt-10 ${theme === 'dark' ? 'white__text' : ''}`}>No Contacts Available</h6>
        )}
        {otherContactList.length === 0 && !flag && (
          <h6 className={`text-center mt-10 ${theme === 'dark' ? 'white__text' : ''}`}>No Contacts Available</h6>
        )}
      </div>
    </div>
  )
}

export default AccountContacts
